import React, { useState, useEffect } from "react";
import Footer from "./common/Footer";
import SalesHeader from "./common/SalesHeader";
import AMHeader from "./common/AMHeader";
import RMHeader from "./common/RMHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";
import * as Constants from "../Constants/index";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Oval from "./loader/CircleLoade";
export default function Registration() {
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [bankDetailShow, setBankDetailShow] = useState("bankdetails");
  const [isSameAsMobile, setIsSameAsMobile] = useState(false);
  const [deliverySameAsPermanent, setDeliverySameAsPermanent] = useState(false);
  const [errors, setErrors] = useState({});

  // api data
  const [stateFetchPer, setStateFetchPer] = useState([]);
  const [districtFetchPer, setDistrictFetchPer] = useState([]);
  const [cityFetchPer, setCityFetchPer] = useState([]);
  const [stateFetch, setStateFetch] = useState([]);
  const [districtFetch, setDistrictFetch] = useState([]);
  const [cityFetch, setCityFetch] = useState([]);

  //basic details
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileWhatsApp, setMobileWhatsApp] = useState("");
  const [dob, setDob] = useState("");
  const [doa, setDoa] = useState("");
  const [marital, setMarital] = useState("single");

  // user model KYC state
  const [photo, setPhoto] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [aadharFrontUrl, setAadharFrontUrl] = useState("");
  const [aadharBackUrl, setAadharBackUrl] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [panFrontUrl, setPanFrontUrl] = useState("");

  //address permanent
  const [addressPer1, setAddressPer1] = useState("");
  const [addressPer2, setAddressPer2] = useState("");
  const [addressPer3, setAddressPer3] = useState("");
  const [cityPer, setCityPer] = useState("");
  const [cityPerOther, setCityPerOther] = useState(false);
  const [cityPerOtherValue, setCityPerOtherValue] = useState("");
  const [cityPerId, setCityPerId] = useState(0);
  const [districtPer, setDistrictPer] = useState("");
  const [districtPerId, setDistrictPerId] = useState(0);
  const [statePer, setStatePer] = useState("");
  const [statePerId, setStatePerId] = useState(0);
  const [pincodePer, setPincodePer] = useState("");

  // address work
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [state, setState] = useState("");
  const [stateId, setStateId] = useState(0);
  const [district, setDistrict] = useState("");
  const [districtId, setDistrictId] = useState(0);
  const [city, setCity] = useState("");
  const [cityOther, setCityOther] = useState(false);
  const [cityOtherValue, setCityOtherValue] = useState("");
  const [cityId, setCityId] = useState(0);
  const [pincode, setPincode] = useState("");

  //bank details
  const [accountNo, setAccountNo] = useState("");
  const [accountType, setAccountType] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [bankName, setBankName] = useState("");
  const [holderName, setHolderName] = useState("");
  const [branch, setBranch] = useState("");
  const [cancelChk, setCancelChk] = useState("");
  const [upiId, setUpiId] = useState("");
  const [upiIdScreen, setUpiIdScreen] = useState("");

  //Influencer Professional Information
  const [profession, setProfession] = useState("");
  const [contractorMobile, setContractorMobile] = useState("");
  // const [teamsize, setTeamsize] = useState('')
  const [noOfSupervisor, setNoOfSupervisor] = useState("");
  const [annualYearly, setAnnualYearly] = useState();
  const [avgNoSite, setAvgNoSite] = useState("");

  //Major Brands Used
  const [brand, setBrand] = useState([]);
  const [otherBrand, setOtherBrand] = useState("");

  //Type and Value of Products Used on Annual Basis
  const [productValue, setInputValues] = useState(Array(6).fill(""));
  const [product, setProduct] = useState([]);
  const p = [
    "Furniture Fittings",
    "Kitchen Fittings",
    "Sliding Fittings",
    "Architectural Hardware",
    "AH - DL",
    "Glass Fittings",
  ];

  // Type of Projects
  const [typeofProjects, setTypeofProjects] = useState("");

  // Solution Category (Does Turnkey Project)
  const [category, setCategory] = useState([]);

  // Top 3 Dealers / Retailers You Buy From
  const [dealer1, setDealer1] = useState("");
  const [dealer2, setDealer2] = useState("");
  const [dealer3, setDealer3] = useState("");

  // If Sub Dealer, Mention Sub Dealer
  const [subDealerName, setSubDealerName] = useState("");

  const formattedDob = moment(dob).format("DD-MM-YYYY");
  const formattedDoa =
    marital === "single" ? "" : moment(doa).format("DD-MM-YYYY");

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    let token = localStorage.getItem("token");

    try {
      axios
        .get(Constants.baseUrl + `master/geography/state?limit=1000`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
          },
        })
        .then((res) => {
          if (res.status === 201) {
            setStateFetchPer(res.data.output.results);
            setStateFetch(res.data.output.results);
          } else {
            // swal(res.data.msg);
          }
        });
    } catch (error) {
      console.log(error);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleInputChange = (index, value) => {
    if (/^\d*$/.test(value)) {
      const newInputValues = [...productValue];
      newInputValues[index] = value;
      setInputValues(newInputValues);
    }
  };

  // call state api by state ID for get permanent district name
  const handleStateChangePer = (event) => {
    const selectedId = event.target.value;
    setDistrictFetchPer([]);
    setCityFetchPer([]);
    setDistrictPer("");
    setCityPer("");
    setPincodePer("");
    setCityPerOther(false)
    setCityPerOtherValue("")
    if (selectedId === "0") {
      // alert('hello')
    } else {
      districtDataPer(selectedId);
    }
    // districtDataPer(selectedId);
  };
  // const selectStatePerRef = useRef(null);

  let token = localStorage.getItem("token");
  const districtDataPer = async (stateid) => {
    try {
      await axios
        .get(Constants.baseUrl + `master/geography/state/${stateid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 10001) {
              setStatePer(res.data.output.name);
              setStatePerId(res.data.output.id);
              setDistrictFetchPer(res.data.output.districts);
            } else {
              // swal(res.data.msg)
            }
          } else {
            // swal(res.data.msg);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // call district api by district id for get permanent city name
  const handleDistrictChangePer = async (event) => {
    const selectedId = event.target.value;
    setCityFetchPer([]);
    setCityPer("");
    setPincodePer("");
    setCityPerOther(false)
    setCityPerOtherValue("")
    if (selectedId === "0") {
      // alert('hello')
    } else {
      cityDataPer(selectedId);
    }
    // cityDataPer(selectedId);
  };

  // const selectdistrictPerRef = useRef(null);

  const cityDataPer = async (cityid) => {
    try {
      await axios
        .get(Constants.baseUrl + `master/geography/district/${cityid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 10001) {
              setDistrictPer(res.data.output.name);
              setDistrictPerId(res.data.output.id);
              
              const citiesData = res.data.output.cities;
              citiesData.push({ id: 0, name: "Other" });
              setCityFetchPer(citiesData);
            } else {
              // swal(res.data.msg)
            }
          } else {
            // swal(res.data.msg);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // call state api by state ID for get delivery district name
  const handleStateChange = async (event) => {
    const selectedId = event.target.value;
    setDistrictFetch([]);
    setCityFetch([]);
    setDistrict("");
    setCity("");
    setCityOther(false)
    setCityOtherValue("")
    setPincode("");
    if (selectedId === "0") {
      // alert('hello')
    } else {
      districtData(selectedId);
    }
  };

  const districtData = async (stateid) => {
    try {
      await axios
        .get(Constants.baseUrl + `master/geography/state/${stateid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 10001) {
              setState(res.data.output.name);
              setStateId(res.data.output.id);
              setDistrictFetch(res.data.output.districts);
            } else {
              // swal(res.data.msg)
            }
          } else {
            // swal(res.data.msg);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // call district api by district id for get delivery city name
  const handleDistrictChange = async (event) => {
    const selectedId = event.target.value;
    setCityFetch([]);
    setCity("");
    setPincode("");
    setCityOther(false)
    setCityOtherValue("")
    if (selectedId === "0") {
      // alert('hello')
    } else {
      cityData(selectedId);
    }
  };

  const cityData = async (cityid) => {
    try {
      await axios
        .get(Constants.baseUrl + `master/geography/district/${cityid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 10001) {
              setDistrict(res.data.output.name);
              setDistrictId(res.data.output.id);
              //setCityFetch(res.data.output.cities);

              const citiesData = res.data.output.cities;
              citiesData.push({ id: 0, name: "Other" });
              setCityFetch(citiesData);

            } else {
              // swal(res.data.msg)
            }
          } else {
            // swal(res.data.msg);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handlePhoto = (event) => {
    updatePhoto(event.target.files[0]);
  };
  const handleAadharFront = (event) => {
    updateAdhaarFront(event.target.files[0]);
  };
  const handleAadharBack = (event) => {
    updateAdhaarBack(event.target.files[0]);
  };
  const handlePanCard = (event) => {
    updatePan(event.target.files[0]);
  };
  const handleCheque = (event) => {
    updateCheque(event.target.files[0]);
  };
  const handleUpiIdScreen = (event) => {
    updateUpiIdScreen(event.target.files[0]);
  };

  const updatePhoto = (file) => {
    let token = localStorage.getItem("token");
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", "photo");
      axios
        .post(Constants.baseUrl + "uploader/upload", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("language"),
            platform: Constants.pt,
            appVersion: Constants.av,
            company: Constants.company,
            program: Constants.program,
          },
        })
        .then((res) => {
          if (res.status === 201) {
            setPhoto(res.data.output.url);
          } else {
            swal("Something went wrong!");
          }
        });
    } catch (error) {
      swal(error);
    }
  };

  const updateAdhaarFront = (file) => {
    let token = localStorage.getItem("token");
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", "aadhar");
      axios
        .post(Constants.baseUrl + "uploader/upload", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("language"),
            platform: Constants.pt,
            appVersion: Constants.av,
            company: Constants.company,
            program: Constants.program,
          },
        })
        .then((res) => {
          if (res.status === 201) {
            setAadharFrontUrl(res.data.output.url);
          } else {
            swal("Something went wrong!");
          }
        });
    } catch (error) {
      swal(error);
    }
  };

  const updateAdhaarBack = (file) => {
    let token = localStorage.getItem("token");
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", "aadhar");

      axios
        .post(Constants.baseUrl + "uploader/upload", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("language"),
            platform: Constants.pt,
            appVersion: Constants.av,
            company: Constants.company,
            program: Constants.program,
          },
        })
        .then((res) => {
          if (res.status === 201) {
            setAadharBackUrl(res.data.output.url);
          } else {
            // swal('Something went wrong!');
          }
        });
    } catch (error) {
      swal(error);
    }
  };

  const updatePan = (file) => {
    let token = localStorage.getItem("token");

    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", "pan");

      axios
        .post(Constants.baseUrl + "uploader/upload", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("language"),
            platform: Constants.pt,
            appVersion: Constants.av,
            company: Constants.company,
            program: Constants.program,
          },
        })
        .then((res) => {
          if (res.status === 201) {
            setPanFrontUrl(res.data.output.url);
          } else {
            // swal('Something went wrong!');
          }
        });
    } catch (error) {
      swal(error);
    }
  };

  const updateCheque = (file) => {
    let token = localStorage.getItem("token");

    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", "cheque");

      axios
        .post(Constants.baseUrl + "uploader/upload", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("language"),
            platform: Constants.pt,
            appVersion: Constants.av,
            company: Constants.company,
            program: Constants.program,
          },
        })
        .then((res) => {
          if (res.status === 201) {
            setCancelChk(res.data.output.url);
          } else {
            // swal('Something went wrong!');
          }
        });
    } catch (error) {
      swal(error);
    }
  };

  const updateUpiIdScreen = (file) => {
    let token = localStorage.getItem("token");

    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", "upiId");

      axios
        .post(Constants.baseUrl + "uploader/upload", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("language"),
            platform: Constants.pt,
            appVersion: Constants.av,
            company: Constants.company,
            program: Constants.program,
          },
        })
        .then((res) => {
          if (res.status === 201) {
            setUpiIdScreen(res.data.output.url);
          } else {
            // swal('Something went wrong!');
          }
        });
    } catch (error) {
      swal(error);
    }
  };

  // add brands
  const addtoBrand = (name) => {
    // console.log(name)
    const brandIndex = brand.indexOf(name);

    // console.log(brandIndex)
    if (brandIndex !== -1) {
      if (name === "Others") {
        setOtherBrand("");
        let filteredBrands = brand.filter(
          (brand) => !brand.startsWith("Others")
        );
        setBrand(filteredBrands);
      }
      // If the brandName already exists, remove it from the array using splice
      else {
        const newBrand = [...brand];
        newBrand.splice(brandIndex, 1);
        setBrand(newBrand);
      }
      // console.log(newBrand)
    } else {
      const newBrand = [...brand];
      newBrand.push(name);
      setBrand(newBrand);
      // console.log("push")
      // console.log(newBrand)
    }
  };

  const setOtherValue = (value) => {
    //  alert(value)
    setOtherBrand(value);
  };
  // add product
  const addtoProduct = (name) => {
    // console.log(name)
    const productIndex = product.indexOf(name);
    // console.log(productIndex)
    if (productIndex !== -1) {
      // If the brandName already exists, remove it from the array using splice
      const newProduct = [...product];
      newProduct.splice(productIndex, 1);
      setProduct(newProduct);
      // console.log(newProduct)
    } else {
      const newProduct = [...product];
      newProduct.push(name);
      setProduct(newProduct);
      // console.log("push")
      // console.log(newProduct)
    }
  };

  // add category
  const addtoCategory = (name) => {
    // console.log(name)
    const categoryIndex = category.indexOf(name);
    console.log(categoryIndex);
    if (categoryIndex !== -1) {
      // If the brandName already exists, remove it from the array using splice
      const newCategory = [...category];
      newCategory.splice(categoryIndex, 1);
      setCategory(newCategory);
      // console.log(newCategory)
    } else {
      const newCategory = [...category];
      newCategory.push(name);
      setCategory(newCategory);
      // console.log("push")
      // console.log(newCategory)
    }
  };

  // validation for save as draft
  const validateSaveAsDraft = () => {
    let errors = {};

    if (!name) {
      errors.name = " Influencer name required";
    } else if (name.length < 3) {
      errors.name = "Influencer name must be at least 3 characters";
    }
    if (!dob) {
      errors.dob = "DOB required";
    }
    if (marital === "married") {
      if (!doa) {
        errors.doa = "DOA required";
      }
    }
    if (!email) {
      errors.email = "E-Mail id required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "E-Mail id invalid";
    }
    if (!mobile) {
      errors.mobile = "Mobile no. required";
    } else if (!/^[6-9]\d{9}$/.test(mobile)) {
      errors.mobile = "Invalid mobile no.";
    } else if (mobile.length !== 10) {
      errors.mobile = "Mobile no. must be 10 digits";
    }
    if (!mobileWhatsApp) {
      errors.mobileWhatsApp = "WhatsApp no. required";
    } else if (!/^[6-9]\d{9}$/.test(mobileWhatsApp)) {
      errors.mobileWhatsApp = "Invalid whatsapp no.";
    } else if (mobileWhatsApp.length !== 10) {
      errors.mobileWhatsApp = "Whatsapp no. must be 10 digits";
    }
    return errors;
  };

  // registration form validation
  const validateForm = () => {
    let errors = {};

    if (!name) {
      errors.name = " Influencer name required";
    } else if (name.length < 3) {
      errors.name = "Influencer name must be at least 3 characters";
    }
    if (!dob) {
      errors.dob = "DOB required";
    }
    if (marital === "married") {
      if (!doa) {
        errors.doa = "DOA required";
      }
    }
    if (!email) {
      errors.email = "E-Mail id required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "E-Mail id invalid";
    }
    if (!mobile) {
      errors.mobile = "Mobile no. required";
    } else if (!/^[6-9]\d{9}$/.test(mobile)) {
      errors.mobile = "Invalid mobile no.";
    } else if (mobile.length !== 10) {
      errors.mobile = "Mobile no. must be 10 digits";
    }
    if (!mobileWhatsApp) {
      errors.mobileWhatsApp = "WhatsApp no. required";
    } else if (!/^[6-9]\d{9}$/.test(mobileWhatsApp)) {
      errors.mobileWhatsApp = "Invalid whatsapp no.";
    } else if (mobileWhatsApp.length !== 10) {
      errors.mobileWhatsApp = "Whatsapp no. must be 10 digits";
    }
    if (!addressPer1) {
      errors.addressPer1 = "House / Flat / Block no. required";
    }
    if (!addressPer2) {
      errors.addressPer2 = "Street / Colony / Locality name required";
    }
    if (!statePer) {
      errors.statePer = "State required";
    }
    if (!districtPer) {
      errors.districtPer = "District required";
    }
    if (!cityPer) {
      errors.cityPer = "City required";
    }
    if (cityPer == "Other" && !cityPerOtherValue) {
      errors.cityPerOtherValue = "Other city required";
    }
    if (!pincodePer) {
      errors.pincodePer = " Pin code required";
    } else if (!/^\d+$/.test(pincodePer)) {
      errors.pincodePer = "Pin code must be numeric";
    } else if (pincodePer.length !== 6) {
      errors.pincodePer = "Pin code must be 6 digits";
    }
    if (!address1) {
      errors.address1 = "House / Flat / Block no. required";
    }
    if (!address2) {
      errors.address2 = "Street / Colony / Locality name required";
    }
    if (!state) {
      errors.state = "State required";
    }
    if (!district) {
      errors.district = "District required";
    }
    if (!city) {
      errors.city = "City required";
    }
    if (city == 'Other' && !cityOtherValue) {
      errors.cityOtherValue = "Other city required";
    }
    if (!pincode) {
      errors.pincode = " Pin code required";
    } else if (!/^\d+$/.test(pincode)) {
      errors.pincode = "Pin code must be numeric";
    } else if (pincode.length !== 6) {
      errors.pincode = "Pin code must be 6 digits";
    }
    if (!photo) {
      errors.photo = "Please select image";
    }
    if (!aadharFrontUrl) {
      errors.aadharFrontUrl = "Please select Image";
    }
    if (!aadharBackUrl) {
      errors.aadharBackUrl = "Please select image";
    }
    if (!aadhar) {
      errors.aadhar = "Aadhar no. required";
    } else if (aadhar.length !== 12) {
      errors.aadhar = "Aadhar No. must be 12 digits";
    } else if (!/^\d+$/.test(aadhar)) {
      errors.aadhar = "Aadhar no. must be numeric";
    }
    // if (!panNumber) {
    //   errors.panNumber = "PAN no. required";
    // } else 
    
    if (panNumber != '' && !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/i.test(panNumber)) {
      errors.panNumber = "Invalid PAN format";
    }

    // if (!panFrontUrl) {
    //   errors.panFrontUrl = "Please select image";
    // }

    // let bankData = holderName && accountNo && accountType && branch && ifsc && cancelChk;
    // let upiData = upiId && upiIdScreen;

    // if(bankData || upiData){
    //   if(bankData){
    //     validateBankForm()
    //     return
    //   }
    //   else if(upiData){
    //     validateUPI()
    //     return
    //   }
    //   else{
    //     swal("Please Fill Bank Details or UPI Details")
    //     return
    //   }
    // }
    return errors;
  };

  // bankdetails form validation
  const validateBankForm = () => {
    let errors = {};
    if (!holderName) {
      errors.holderName = "Account holder name required";
    } else if (holderName.length < 3) {
      errors.holderName = "Account holder name must be at least 3 characters";
    }
    if (!accountType) {
      errors.accountType = "Account type required";
    }
    if (!bankName) {
      errors.bankName = "Bank name required";
    }
    if (!accountNo) {
      errors.accountNo = "Account no. required";
    } else if (!/^\d+$/.test(accountNo)) {
      errors.accountNo = "Account no. must be numeric";
    } else if (accountNo.length < 5) {
      errors.accountNo = "Account no. must be at least 5 characters";
    }
    if (!branch) {
      errors.branch = "Branch name required";
    } else if (branch.length < 2) {
      errors.name = "branch name must be at least 2 characters";
    }
    if (!ifsc) {
      errors.ifsc = "IFSC code required";
    } else if (ifsc.length !== 11) {
      errors.ifsc = "IFSC code must be 11 digits";
    }
    if (!cancelChk) {
      errors.cancelChk = "Please select image";
    }
    return errors;
  };
  //upi details validation
  const validateUPI = () => {
    let errors = {};
    if (!upiId) {
      errors.upiId = "UPI id required";
    } else if (!upiId.includes("@")) {
      errors.upiId = "Invalid upi id";
    }
    if (!upiIdScreen) {
      errors.upiIdScreen = "Please select image";
    }
    return errors;
  };

  //call register api for plumber registration
  const register = async (event) => {
    let token = localStorage.getItem("token");
    event.preventDefault();
    setErrors({});

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return; // Don't proceed with the API call if there are validation errors
    }

      let bankData =
        holderName && accountNo && accountType && branch && ifsc && cancelChk;
      let upiData = upiId && upiIdScreen;

      // Check if at least one of the data sets is present
      if (bankData || upiData) {
        if (bankData) {
          const bankErrors = validateBankForm();
          if (Object.keys(bankErrors).length > 0) {
            setErrors(bankErrors);
            return; // Prevent API call if bank validation fails
          }
        }

        if (upiData) {
          const upiErrors = validateUPI();
          if (Object.keys(upiErrors).length > 0) {
            setErrors(upiErrors);
            return; // Prevent API call if UPI validation fails
          }
        }
      } else {
        swal("Please Fill Bank Details or UPI Details");
        return;
      }

    try {
      let brandIndex = brand.indexOf("Others");
      let filteredBrands = brand.filter((brand) => !brand.startsWith("Others"));
      setBrand(filteredBrands);
      let newBrand = brand;
      if (otherBrand !== "") {
        if (brandIndex === -1) {
          newBrand = [...brand];
          newBrand.push("Others" + "|" + otherBrand);
        } else {
          newBrand = [...brand];
          newBrand.splice(brandIndex, 1);
          newBrand.push("Others" + "|" + otherBrand);
        }
      }

      setLoading(true);
      await axios
        .post(
          Constants.baseUrl + "user/manage",
          {
            name: name,
            email: email,
            mobile: mobile,
            mobileWhatsApp: mobileWhatsApp,
            dob: formattedDob,
            doa: formattedDoa,
            marital: marital,
            status: "VERIFIED",
            userModelKYC: {
              photo: photo,
              // aadharMobile: mobileAadhar,
              aadharNumber: aadhar,
              aadharFrontUrl: aadharFrontUrl,
              aadharBackUrl: aadharBackUrl,
              aadharVerify: 0,
              aadharVerifiedBy: "",
              aadharVerifiedOn: "2023-06-03T12:34:47.943Z",
              panNumber: panNumber,
              panFrontUrl: panFrontUrl,
              panVerify: 0,
              panVerifiedBy: "",
              panVerifiedOn: "2023-06-03T12:34:47.943Z",
            },
            addressWork: {
              workAddress1: address1,
              workAddress2: address2,
              workAddress3: address3,
              workCity: (city == "Other") ? cityOtherValue : city,
              workCityId: cityId,
              workState: state,
              workStateId: stateId,
              workdistrict: district,
              workdistrictId: districtId,
              workPincode: pincode,
              workCountry: "",
            },
            addressPerm: {
              permAddress1: addressPer1,
              permAddress2: addressPer2,
              permAddress3: addressPer3,
              permCity: (cityPer == "Other") ? cityPerOtherValue : cityPer,
              permCityId: cityPerId,
              permState: statePer,
              permStateId: statePerId,
              permdistrict: districtPer,
              permdistrictId: districtPerId,
              permPincode: pincodePer,
              permCountry: "",
            },
            bankDetail: {
              bankAccountNo: accountNo,
              accountType: accountType,
              ifsc: ifsc,
              beneficiary: "",
              bankName: bankName,
              holderName: holderName,
              branchName: branch,
              cancelChk: cancelChk,
              upiId: upiId,
              upiIdScreen: upiIdScreen,
            },
            userDetails: {
              contractMobile: contractorMobile,
              teamSize: "",
              supervisors: noOfSupervisor,
              annualYearly: annualYearly,
              noOfSites: avgNoSite,
              brandsUsed: newBrand?.join(","),
              productType: product?.join(","),
              productValue: productValue?.join(","),
              typeofProjects: typeofProjects,
              solutionCategory: category?.join(","),
              dealer1: dealer1,
              dealer2: dealer2,
              dealer3: dealer3,
              subDealer: subDealerName,
              profession: profession,
            },
            parentId: 0,
            userType: "MEMBER",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
          }
        )
        .then((res) => {
          if (res.status === 201) {
            if (res.data.code === 10001) {
              swal({
                text: "User " + res.data.msg,
                icon: "success",
                timer: 1000,
                buttons: false,
              });
              window.location = "/registration";
            } else if (res.data.code === 12001) {
              swal({
                text: res.data.msg,
                icon: "error",
                timer: 1000,
                buttons: false,
              });
            } else {
              swal({
                text: res.data.msg,
                icon: "error",
                timer: 1000,
                buttons: false,
              });
            }
          } else {
            // swal(res.data.msg)
          }
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const saveAsDraft = async (event) => {
    let token = localStorage.getItem("token");
    event.preventDefault();

    setErrors({});

    const validationErrors = validateSaveAsDraft();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return; // Don't proceed with the API call if there are validation errors
    }
    try {
      let brandIndex = brand.indexOf("Others");
      let filteredBrands = brand.filter((brand) => !brand.startsWith("Others"));
      setBrand(filteredBrands);
      let newBrand = brand;
      if (otherBrand !== "") {
        if (brandIndex === -1) {
          newBrand = [...brand];
          newBrand.push("Others" + "|" + otherBrand);
        } else {
          newBrand = [...brand];
          newBrand.splice(brandIndex, 1);
          newBrand.push("Others" + "|" + otherBrand);
        }
      }
      //   alert(brand.join(','))
      setLoading(true);
      await axios
        .post(
          Constants.baseUrl + "user/manage",
          {
            name: name,
            email: email,
            mobile: mobile,
            mobileWhatsApp: mobileWhatsApp,
            dob: formattedDob,
            doa: formattedDoa,
            marital: marital,
            status: "DRAFT",
            userModelKYC: {
              photo: photo,
              // aadharMobile: mobileAadhar,
              aadharNumber: aadhar,
              aadharFrontUrl: aadharFrontUrl,
              aadharBackUrl: aadharBackUrl,
              aadharVerify: 0,
              aadharVerifiedBy: "",
              aadharVerifiedOn: "2023-06-03T12:34:47.943Z",
              panNumber: panNumber,
              panFrontUrl: panFrontUrl,
              panVerify: 0,
              panVerifiedBy: "",
              panVerifiedOn: "2023-06-03T12:34:47.943Z",
            },
            addressWork: {
              workAddress1: address1,
              workAddress2: address2,
              workAddress3: address3,
              workCity: city,
              workCityId: cityId,
              workState: state,
              workStateId: stateId,
              workdistrict: district,
              workdistrictId: districtId,
              workPincode: pincode,
              workCountry: "",
            },
            addressPerm: {
              permAddress1: addressPer1,
              permAddress2: addressPer2,
              permAddress3: addressPer3,
              permCity: cityPer,
              permCityId: cityPerId,
              permState: statePer,
              permStateId: statePerId,
              permdistrict: districtPer,
              permdistrictId: districtPerId,
              permPincode: pincodePer,
              permCountry: "",
            },
            bankDetail: {
              bankAccountNo: accountNo,
              accountType: accountType,
              ifsc: ifsc,
              beneficiary: "",
              bankName: bankName,
              holderName: holderName,
              branchName: branch,
              cancelChk: cancelChk,
              upiId: upiId,
              upiIdScreen: upiIdScreen,
            },
            userDetails: {
              contractMobile: contractorMobile,
              teamSize: "",
              supervisors: noOfSupervisor,
              annualYearly: annualYearly,
              noOfSites: avgNoSite,
              brandsUsed: newBrand?.join(","),
              productType: product?.join(","),
              productValue: productValue?.join(","),
              typeofProjects: typeofProjects,
              solutionCategory: category?.join(","),
              dealer1: dealer1,
              dealer2: dealer2,
              dealer3: dealer3,
              subDealer: subDealerName,
              profession: profession,
            },
            parentId: 0,
            userType: "MEMBER",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
          }
        )
        .then((res) => {
          if (res.status === 201) {
            if (res.data.code === 10001) {
              swal({
                text: "Registration saved as draft",
                icon: "success",
                timer: 1000,
                buttons: false,
              });
              window.location = "/registration";
            } else if (res.data.code === 12001) {
              swal({
                text: res.data.msg,
                icon: "error",
                timer: 1000,
                buttons: false,
              });
            } else {
              swal({
                text: res.data.msg,
                icon: "error",
                timer: 1000,
                buttons: false,
              });
            }
          } else {
            // swal(res.data.msg)
          }
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // scroll functionality
  const handleScroll = () => {
    const scrollTop = window.pageYOffset;

    if (scrollTop > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // whatsapp number same as mobile
  const handleCheckboxChange = (event) => {
    setIsSameAsMobile(event.target.checked);
    if (event.target.checked) {
      setMobileWhatsApp(mobile);
    } else {
      setMobileWhatsApp("");
    }
  };

  // address same as permanent functionality start
  const handleCheckboxChangeAdd = (event) => {
    setDeliverySameAsPermanent(event.target.checked);

    if (event.target.checked) {
      setAddress1(addressPer1);
      setAddress2(addressPer2);
      setAddress3(addressPer3);
      setPincode(pincodePer);
      setState(statePer);
      setStateId(statePerId);
      setDistrict(districtPer);
      setDistrictId(districtPerId);
      setCity(cityPer);
      setCityId(cityPerId);

      if(cityPer == 'Other') {
        setCityOther(true)
        setCityOtherValue(cityPerOtherValue)
      }
      else
      {
        setCityOther(false)
        setCityOtherValue("")
      }

      // if (!isNaN(statePer)) {
      //     setState(statePer);
      // }
      // else {
      //     const options = selectStatePerRef.current.options;
      //     for (let i = 0; i < options.length; i++) {
      //         if (options[i].label === statePer) {
      //             setState(options[i].value);
      //             break;
      //         }
      //     }
      // }
    } else {
      // Reset delivery address inputs
      setAddress1("");
      setAddress2("");
      setAddress3("");
      setPincode("");
      setState("");
      setStateId(0);
      setDistrict("");
      setDistrictId(0);
      setCity("");
      setCityId(0);
      setCityOther(false)
      setCityOtherValue("")
    }
  };

  const handleProfession = (event) => {
    setProfession(event.target.value);
    if (event.target.value === "contractor") {
      setContractorMobile("");
    }
  };

  // Calculate the exact date 18 years ago from today
  const exactMinDate = new Date();
  exactMinDate.setFullYear(exactMinDate.getFullYear() - 18);
  exactMinDate.setHours(0, 0, 0, 0);

  let headerComponent;

  if (localStorage.getItem("user_type") === "SALES") {
    headerComponent = <SalesHeader />;
  } else if (localStorage.getItem("user_type") === "SH") {
    headerComponent = <AMHeader />;
  } else if (localStorage.getItem("user_type") === "RO") {
    headerComponent = <RMHeader />;
  }
  return (
    <>
      {loading ? <Oval /> : null}
      {headerComponent}
      <section className="blog-area pt182">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="all-single-blog">
                <div className="row">
                  <div className="col-md-12">
                    <div className="heading-banner-title">
                      <h4>Registration Form</h4>
                    </div>
                  </div>
                </div>
                <div className="row clearfix">
                  <div className="col-md-12 col-xs-12">
                    <h4 className="midhead">Influencer Details </h4>
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Name *</label>
                      <input
                        autoComplete="nope"
                        className="form-control ignore"
                        maxLength="35"
                        placeholder="Name*"
                        type="text"
                        value={name}
                        onChange={(event) => {
                          const input = event.target.value;
                          if (/^[a-zA-Z\s]*$/.test(input)) {
                            // Only alphabetic characters
                            setName(input);
                          }
                        }}
                      />
                    </div>
                    {errors.name && (
                      <div className="error-message">{errors.name}</div>
                    )}
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>DOB *</label>
                      <DatePicker
                        selected={dob}
                        onChange={(date) => setDob(date)}
                        dateFormat="dd-MM-yyyy "
                        className="form-control"
                        value={dob}
                        showYearDropdown
                        showMonthDropdown
                        placeholderText="dd-mm-yyyy"
                        maxDate={exactMinDate} // Up to the exact 18th birthday
                        onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                        onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                        onClick={(e) => e.preventDefault()} // Preven
                      />
                    </div>
                    {errors.dob && (
                      <div className="error-message">{errors.dob}</div>
                    )}
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Marital Status *</label>
                      <div className="h34">
                        <label className="radio-inline">
                          <input
                            type="radio"
                            name="marital"
                            value="single"
                            checked={marital === "single"}
                            onChange={(event) => setMarital(event.target.value)}
                          />{" "}
                          <span> Single</span>
                        </label>
                        <label className="radio-inline">
                          <input
                            type="radio"
                            name="marital"
                            value="married"
                            checked={marital === "married"}
                            onChange={(event) => setMarital(event.target.value)}
                          />
                          <span> Married</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {marital === "married" ? (
                    <div
                      className="col-md-3 col-sm-12 col-xs-12 annidtclassName"
                      id="divannidt"
                    >
                      <div className="form-group has-float-label style-two">
                        <label>Anniversary Date</label>
                        <DatePicker
                          selected={doa}
                          onChange={(date) => setDoa(date)}
                          dateFormat="dd-MM-yyyy"
                          className="form-control"
                          value={doa}
                          showYearDropdown
                          showMonthDropdown
                          placeholderText="dd-mm-yyyy"
                          maxDate={new Date()}
                          onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                          onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                          onClick={(e) => e.preventDefault()} // Preven
                        />
                      </div>
                      {errors.doa && (
                        <div className="error-message">{errors.doa}</div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>E-Mail ID *</label>
                      <input
                        autoComplete="nope"
                        className="form-control"
                        data-val="true"
                        id=""
                        placeholder="E-Mail ID *"
                        type="text"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </div>
                    {errors.email && (
                      <div className="error-message">{errors.email}</div>
                    )}
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Mobile No. *</label>
                      <input
                        autoComplete="nope"
                        className="form-control"
                        maxLength="10"
                        minLength="10"
                        placeholder="Mobile No."
                        type="text"
                        value={mobile}
                        onChange={(event) => {
                          const input = event.target.value;
                          if (/^\d*$/.test(input)) {
                            setMobile(input);
                          }
                        }}
                      />
                    </div>
                    {errors.mobile && (
                      <div className="error-message">{errors.mobile}</div>
                    )}
                  </div>

                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Whatsapp Number *</label>
                      <input
                        maxLength="10"
                        className="form-control"
                        minLength="10"
                        name="mobileWhatsApp"
                        placeholder="Whatsapp Number"
                        type="text"
                        value={mobileWhatsApp}
                        onChange={(event) => {
                          const input = event.target.value;
                          if (/^\d*$/.test(input)) {
                            setMobileWhatsApp(input);
                          }
                        }}
                        disabled={isSameAsMobile}
                      />

                      <div className="input-checkbox">
                        <input
                          className="checkbox-inline mb-10 cllrs"
                          data-val="true"
                          type="checkbox"
                          checked={isSameAsMobile}
                          onChange={handleCheckboxChange}
                        />
                        <label className="checkbox-inline  cllrs check-no">
                          If Whatsapp No. is same as Mobile No.
                        </label>
                      </div>
                    </div>
                    {errors.mobileWhatsApp && (
                      <div className="error-message">
                        {errors.mobileWhatsApp}
                      </div>
                    )}
                  </div>
                  <div className="col-md-12 col-xs-12">
                    <h4 className="midhead">
                      Permanent Address<span>*</span>{" "}
                    </h4>
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>House / Flat / Block No. *</label>
                      <input
                        autoComplete="nope"
                        className="form-control"
                        maxLength="70"
                        placeholder="House / Flat / Block No.  *"
                        type="text"
                        value={addressPer1}
                        onChange={(event) => setAddressPer1(event.target.value)}
                      />
                    </div>
                    {errors.addressPer1 && (
                      <div className="error-message">{errors.addressPer1}</div>
                    )}
                  </div>

                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Street / Colony / Locality Name *</label>
                      <input
                        autoComplete="nope"
                        className="form-control"
                        id=""
                        maxLength="70"
                        placeholder="Street / Colony / Locality Name *"
                        type="text"
                        value={addressPer2}
                        onChange={(event) => setAddressPer2(event.target.value)}
                      />
                    </div>
                    {errors.addressPer2 && (
                      <div className="error-message">{errors.addressPer2}</div>
                    )}
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Landmark</label>
                      <input
                        autoComplete="nope"
                        className="form-control"
                        id=""
                        maxLength="70"
                        placeholder="Landmark(Optional)"
                        type="text"
                        value={addressPer3}
                        onChange={(event) => setAddressPer3(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>State *</label>
                      <select
                        className="form-control2"
                        onChange={handleStateChangePer}
                      >
                        <option value="0">Select</option>
                        {stateFetchPer.map((item, index) => {
                          return (
                            <>
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                    {errors.statePer && (
                      <div className="error-message">{errors.statePer}</div>
                    )}
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>District *</label>
                      <select
                        className="form-control2"
                        onChange={handleDistrictChangePer}
                      >
                        <option value="0">Select</option>
                        {districtFetchPer.map((item, index) => {
                          return (
                            <>
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                    {errors.districtPer && (
                      <div className="error-message">{errors.districtPer}</div>
                    )}
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>City *</label>
                      <select
                        className="form-control2"
                        value={cityPer}
                        onChange={(event) => {
                          if (event.target.value != "Other") {
                            setCityPer(event.target.value);
                            const selectedCityPer = cityFetchPer.find(
                              (item) => item.name === event.target.value
                            );
                            setCityPerId(
                              selectedCityPer ? selectedCityPer.id : ""
                            );

                            setCityPerOther(false);
                          } else {
                            setCityPerOther(true);
                            setCityPerId(0);
                            setCityPer("Other");
                          }
                        }}
                      >
                        <option value="">Select</option>
                        {cityFetchPer.map((item, index) => {
                          return (
                            <>
                              <option key={index} value={item.name}>
                                {item.name}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                    {errors.cityPer && (
                      <div className="error-message">{errors.cityPer}</div>
                    )}
                  </div>
                  {cityPerOther && (
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label>Other City *</label>
                        <input
                          autoComplete="nope"
                          className="form-control"
                          placeholder="Other City *"
                          type="text"
                          value={cityPerOtherValue}
                          onChange={(event) => {
                            setCityPerOtherValue(event.target.value);
                          }}
                        />
                      </div>
                      {errors.cityPerOtherValue && (
                        <div className="error-message">
                          {errors.cityPerOtherValue}
                        </div>
                      )}
                    </div>
                  )}
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Pin Code *</label>
                      <input
                        autoComplete="nope"
                        className="form-control"
                        maxLength="6"
                        minLength="6"
                        placeholder="Pin Code  *"
                        type="text"
                        value={pincodePer}
                        onChange={(event) => {
                          const input = event.target.value;
                          if (/^\d*$/.test(input)) {
                            setPincodePer(input);
                          }
                        }}
                      />
                    </div>
                    {errors.pincodePer && (
                      <div className="error-message">{errors.pincodePer}</div>
                    )}
                  </div>
                  <div className="col-md-12 col-xs-12">
                    <h4 className="midhead">
                      Delivery Address<span>*</span>{" "}
                    </h4>
                    <input
                      className="checkbox-inline mb-10 cllrs"
                      id="deliveryCheckbox"
                      type="checkbox"
                      checked={deliverySameAsPermanent}
                      onChange={handleCheckboxChangeAdd}
                    />
                    &nbsp; If Delivery address is same as permanent address
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>House / Flat / Block No. *</label>
                      <input
                        autoComplete="nope"
                        className="form-control"
                        id=""
                        maxLength="70"
                        name=""
                        placeholder="House / Flat / Block No. *"
                        type="text"
                        value={address1}
                        onChange={(event) => setAddress1(event.target.value)}
                        disabled={deliverySameAsPermanent}
                      />
                    </div>
                    {errors.address1 && (
                      <div className="error-message">{errors.address1}</div>
                    )}
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Street / Colony / Locality Name *</label>
                      <input
                        autoComplete="nope"
                        className="form-control"
                        id=""
                        maxLength="70"
                        name=""
                        placeholder="Street / Colony / Locality Name  *"
                        type="text"
                        value={address2}
                        onChange={(event) => setAddress2(event.target.value)}
                        disabled={deliverySameAsPermanent}
                      />
                    </div>
                    {errors.address2 && (
                      <div className="error-message">{errors.address2}</div>
                    )}
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Landmark</label>
                      <input
                        autoComplete="nope"
                        className="form-control"
                        id=""
                        maxLength="70"
                        name=""
                        placeholder="Landmark"
                        type="text"
                        value={address3}
                        onChange={(event) => setAddress3(event.target.value)}
                        disabled={deliverySameAsPermanent}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>State *</label>
                      {/* <select className="form-control2" value={state} ref={selectStatePerRef} onChange={handleStateChange} disabled={deliverySameAsPermanent}> */}
                      <select
                        className="form-control2"
                        onChange={handleStateChange}
                        disabled={deliverySameAsPermanent}
                      >
                        {deliverySameAsPermanent ? (
                          <option value={statePer}>{statePer}</option>
                        ) : (
                          <>
                            <option value="0">Select</option>
                            {stateFetch.map((item, index) => (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                    {errors.state && (
                      <div className="error-message">{errors.state}</div>
                    )}
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>District *</label>
                      <select
                        className="form-control2"
                        onChange={handleDistrictChange}
                        disabled={deliverySameAsPermanent}
                      >
                        {deliverySameAsPermanent ? (
                          <option value={districtPer}>{districtPer}</option>
                        ) : (
                          <>
                            <option value="0">Select</option>
                            {districtFetch.map((item, index) => (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                    {errors.district && (
                      <div className="error-message">{errors.district}</div>
                    )}
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>City *</label>
                      <select
                        className="form-control2"
                        value={city}
                        onChange={(event) => {
                          if (event.target.value != "Other") {
                            setCity(event.target.value);
                            const selectedCity = cityFetch.find(
                              (item) => item.name === event.target.value
                            );
                            setCityId(selectedCity ? selectedCity.id : "");

                            setCityOther(false);
                          } else {
                            setCityOther(true);
                            setCityId(0);
                            setCity("Other");
                          }
                        }}
                        disabled={deliverySameAsPermanent}
                      >
                        {deliverySameAsPermanent ? (
                          <option value={cityPer}>{cityPer}</option>
                        ) : (
                          <>
                            <option value="0">Select</option>
                            {cityFetch.map((item, index) => (
                              <option key={index} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                    {errors.city && (
                      <div className="error-message">{errors.city}</div>
                    )}
                  </div>

                  {cityOther && (
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label>Other City *</label>
                        <input
                          autoComplete="nope"
                          className="form-control"
                          placeholder="Other City *"
                          type="text"
                          value={cityOtherValue}
                          onChange={(event) => {
                            setCityOtherValue(event.target.value);
                          }}
                        />
                      </div>
                      {errors.cityOtherValue && (
                        <div className="error-message">
                          {errors.cityOtherValue}
                        </div>
                      )}
                    </div>
                  )}

                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Pin Code *</label>
                      <input
                        autoComplete="nope"
                        className="form-control"
                        data-val="true"
                        id=""
                        maxLength="6"
                        minLength="6"
                        name=""
                        placeholder="Pin Code  *"
                        type="text"
                        value={pincode}
                        onChange={(event) => {
                          const input = event.target.value;
                          if (/^\d*$/.test(input)) {
                            setPincode(input);
                          }
                        }}
                        disabled={deliverySameAsPermanent}
                      />
                    </div>
                    {errors.pincode && (
                      <div className="error-message">{errors.pincode}</div>
                    )}
                  </div>
                </div>
                <div className="row clearfix mt-15">
                  <div className="col-md-12 col-xs-12">
                    <h4 className="midhead">Upload ID Proof</h4>
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Upload Photo *</label>
                      {photo !== "" ? (
                        <Link to={photo} target="_blank">
                          <span>
                            <i className="fa fa-eye">View</i>
                          </span>
                        </Link>
                      ) : (
                        ""
                      )}
                      <input
                        className="form-control"
                        id=""
                        name=""
                        accept=".jpg, .jpeg, .png, .svg"
                        type="file"
                        onChange={handlePhoto}
                      />
                    </div>
                    {errors.photo && (
                      <div className="error-message">{errors.photo}</div>
                    )}
                  </div>

                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Upload Aadhar Card Front*</label>
                      {aadharFrontUrl !== "" ? (
                        <Link to={aadharFrontUrl} target="_blank">
                          <span>
                            <i className="fa fa-eye">View</i>
                          </span>
                        </Link>
                      ) : (
                        ""
                      )}
                      <input
                        className="form-control"
                        id=""
                        name=""
                        accept=".jpg, .jpeg, .png, .svg"
                        type="file"
                        onChange={handleAadharFront}
                      />
                      {/* <span className="ff">Front Copy</span> */}
                    </div>
                    {errors.aadharFrontUrl && (
                      <div className="error-message">
                        {errors.aadharBackUrl}
                      </div>
                    )}
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Upload Aadhar Card Back*</label>
                      {aadharBackUrl !== "" ? (
                        <Link to={aadharBackUrl} target="_blank">
                          <span>
                            <i className="fa fa-eye">View</i>
                          </span>
                        </Link>
                      ) : (
                        ""
                      )}
                      <input
                        className="form-control"
                        id=""
                        name=""
                        accept=".jpg, .jpeg, .png, .svg"
                        type="file"
                        onChange={handleAadharBack}
                      />
                      {/* <span className="ff">Back Copy</span> */}
                    </div>
                    {errors.aadharBackUrl && (
                      <div className="error-message">
                        {errors.aadharBackUrl}
                      </div>
                    )}
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Aadhar No. *</label>
                      <input
                        autoComplete="nope"
                        className="form-control"
                        maxLength="12"
                        minLength="12"
                        name="AadharNo"
                        placeholder="Aadhar No."
                        type="text"
                        value={aadhar}
                        onChange={(event) => {
                          const input = event.target.value;
                          if (/^\d*$/.test(input)) {
                            setAadhar(input);
                          }
                        }}
                      />
                    </div>
                    {errors.aadhar && (
                      <div className="error-message">{errors.aadhar}</div>
                    )}
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Upload PAN Card</label>
                      {panFrontUrl !== "" ? (
                        <Link to={panFrontUrl} target="_blank">
                          <span>
                            <i className="fa fa-eye">View</i>
                          </span>
                        </Link>
                      ) : (
                        ""
                      )}
                      <input
                        className="form-control"
                        id=""
                        name=""
                        accept=".jpg, .jpeg, .png, .svg"
                        type="file"
                        onChange={handlePanCard}
                      />
                    </div>
                    {/* {errors.panFrontUrl && (
                      <div className="error-message">{errors.panFrontUrl}</div>
                    )} */}
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label> PAN No. </label>
                      <input
                        autoComplete="nope"
                        className="form-control"
                        id=""
                        maxLength="10"
                        minLength="10"
                        name=""
                        placeholder="PAN No."
                        type="text"
                        value={panNumber}
                        onChange={(event) =>
                          setPanNumber(event.target.value.toUpperCase())
                        }
                      />
                    </div>
                    {/* {errors.panNumber && (
                      <div className="error-message">{errors.panNumber}</div>
                    )} */}
                  </div>
                  <div className="col-md-12 col-xs-12">
                    <h4 className="midhead">Influencer Bank Details </h4>
                  </div>
                  {/* <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <div className="h34">
                        <label className="radio-inline">
                          <input
                            id="chckbanking"
                            name="PaymentMode"
                            type="radio"
                            checked={bankDetailShow === "bankdetails"}
                            value="bankdetails"
                            onChange={(event) => {
                              if (event.target.checked) {
                                setBankDetailShow("bankdetails");
                                setUpiId("");
                                setUpiIdScreen("");
                              }
                            }}
                          />
                          <span> Bank Details</span>
                        </label>
                        <label className="radio-inline">
                          <input
                            id="chckupi"
                            name="PaymentMode"
                            type="radio"
                            checked={bankDetailShow === "upidetails"}
                            value="upidetails"
                            onChange={(event) => {
                              if (event.target.checked) {
                                setBankDetailShow("upidetails");
                                setHolderName("");
                                setAccountType("");
                                setBankName("");
                                setAccountNo("");
                                setBranch("");
                                setIfsc("");
                                setCancelChk("");
                              }
                            }}
                          />{" "}
                          <span> UPI Details</span>
                        </label>
                      </div>
                    </div>
                  </div> */}
                  {/* {bankDetailShow === "bankdetails" ? ( */}
                  <div
                    className="bankdetails col-md-12 col-xs-12"
                    id="bankdetails"
                  >
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label>Account Holder Name</label>
                        <input
                          autoComplete="nope"
                          className="form-control"
                          maxLength="35"
                          placeholder="Account Holder Name"
                          type="text"
                          value={holderName}
                          onChange={(event) => {
                            const input = event.target.value;
                            if (/^[a-zA-Z\s]*$/.test(input)) {
                              // Only alphabetic characters
                              setHolderName(input);
                            }
                          }}
                        />
                      </div>
                      {errors.holderName && (
                        <div className="error-message">{errors.holderName}</div>
                      )}
                    </div>
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label>Account Type</label>
                        <select
                          className="form-control2"
                          data-val="true"
                          data-val-required="Account Type Required"
                          id=""
                          style={{ alignItem: "center" }}
                          value={accountType}
                          onChange={(event) =>
                            setAccountType(event.target.value)
                          }
                        >
                          <option value="">Select Account Type</option>
                          <option value="Current account">
                            Current account
                          </option>
                          <option value="Savings account">
                            Savings account
                          </option>
                          {/* <option value="Salary account">
                              Salary account
                            </option> */}
                        </select>
                      </div>
                      {errors.accountType && (
                        <div className="error-message">
                          {errors.accountType}
                        </div>
                      )}
                    </div>
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label>Bank Name</label>
                        <select
                          autoComplete="off"
                          className="form-control2"
                          data-val="true"
                          data-val-required="Bank Name Required"
                          id="BankName"
                          name="BankName"
                          value={bankName}
                          onChange={(event) => setBankName(event.target.value)}
                        >
                          <option selected="selected" value="">
                            Select Bank Name
                          </option>
                          <option value="Airtel Payments Bank">
                            Airtel Payments Bank
                          </option>
                          <option value="Allahabad Bank">Allahabad Bank</option>
                          <option value="Andhra Pragathi Grameena Bank">
                            Andhra Pragathi Grameena Bank
                          </option>
                          <option value="Andhra Pradesh Grameena Vikas Bank">
                            Andhra Pradesh Grameena Vikas Bank
                          </option>
                          <option value="Arunachal Pradesh Rural Bank">
                            Arunachal Pradesh Rural Bank
                          </option>
                          <option value="Aryavart Bank">Aryavart Bank</option>
                          <option value="Assam Gramin Vikash Bank">
                            Assam Gramin Vikash Bank
                          </option>
                          <option value="Au Small Finance Bank">
                            Au Small Finance Bank
                          </option>
                          <option value="Apna Sahakari Bank Ltd">
                            Apna Sahakari Bank Ltd
                          </option>
                          <option value="Axis Bank">Axis Bank</option>
                          <option value="Bandhan Bank">Bandhan Bank</option>
                          <option value="Bank of Baroda">Bank of Baroda</option>
                          <option value="Bank of India">Bank of India</option>
                          <option value="Bank of Maharashtra">
                            Bank of Maharashtra
                          </option>
                          <option value="Bangiya Gramin Vikas Bank">
                            Bangiya Gramin Vikas Bank
                          </option>
                          <option value="Baroda Gujarat Gramin Bank">
                            Baroda Gujarat Gramin Bank
                          </option>
                          <option value="Baroda Rajasthan Kshetriya Gramin Bank">
                            Baroda Rajasthan Kshetriya Gramin Bank
                          </option>
                          <option value="Baroda UP Bank">Baroda UP Bank</option>
                          <option value="Canara Bank">Canara Bank</option>
                          <option value="Capital Small Finance Bank">
                            Capital Small Finance Bank
                          </option>
                          <option value="Central Bank of India">
                            Central Bank of India
                          </option>
                          <option value="Chaitanya Godavari Grameena Bank">
                            Chaitanya Godavari Grameena Bank
                          </option>
                          <option value="Chhattisgarh Rajya Gramin Bank">
                            Chhattisgarh Rajya Gramin Bank
                          </option>
                          <option value="CSB Bank">CSB Bank</option>
                          <option value="City Union Bank">
                            City Union Bank
                          </option>
                          <option value="Corporation Bank">
                            Corporation Bank
                          </option>
                          <option value="Dakshin Bihar Gramin Bank">
                            Dakshin Bihar Gramin Bank
                          </option>
                          <option value="DCB Bank">DCB Bank</option>
                          <option value="Dhanlaxmi Bank">Dhanlaxmi Bank</option>
                          <option value="Ellaquai Dehati Bank">
                            Ellaquai Dehati Bank
                          </option>
                          <option value="Equitas Small Finance Bank">
                            Equitas Small Finance Bank
                          </option>
                          <option value="ESAF Small Finance Bank">
                            ESAF Small Finance Bank
                          </option>
                          <option value="Federal Bank">Federal Bank</option>
                          <option value="Fincare Small Finance Bank">
                            Fincare Small Finance Bank
                          </option>
                          <option value="Fino Payments Bank">
                            Fino Payments Bank
                          </option>
                          <option value="HDFC Bank">HDFC Bank</option>
                          <option value="Himachal Pradesh Gramin Bank">
                            Himachal Pradesh Gramin Bank
                          </option>
                          <option value="IDBI Bank">IDBI Bank</option>
                          <option value="Indian Bank">Indian Bank</option>
                          <option value="India Post Payments Bank">
                            India Post Payments Bank
                          </option>
                          <option value="Indian Overseas Bank">
                            Indian Overseas Bank
                          </option>
                          <option value="ICICI Bank">ICICI Bank</option>
                          <option value="Induslnd Bank">Induslnd Bank</option>
                          <option value="IDFC First Bank">
                            IDFC First Bank
                          </option>
                          <option value="Jammu &amp; Kashmir Bank">
                            Jammu &amp; Kashmir Bank
                          </option>
                          <option value="Janata Sahakari Bank">
                            Janata Sahakari Bank
                          </option>
                          <option value="Jana Small Finance Bank">
                            Jana Small Finance Bank
                          </option>
                          <option value="J&amp;K Grameen Bank">
                            J&amp;K Grameen Bank
                          </option>
                          <option value="Jharkhand Rajya Gramin Bank">
                            Jharkhand Rajya Gramin Bank
                          </option>
                          <option value="Janaseva Sahakari Bank Ltd">
                            Janaseva Sahakari Bank Ltd
                          </option>
                          <option value="Jio Payment Bank">
                          Jio Payment Bank
                          </option>
                          <option value="Karnataka Bank Ltd">
                            Karnataka Bank Ltd
                          </option>
                          <option value="Karnataka Gramin Bank">
                            Karnataka Gramin Bank
                          </option>
                          <option value="Karnataka Vikas Grameena Bank">
                            Karnataka Vikas Grameena Bank
                          </option>
                          <option value="Kerala Gramin Bank">
                            Kerala Gramin Bank
                          </option>
                          <option value="Kotak Mahindra Bank">
                            Kotak Mahindra Bank
                          </option>
                          <option value="Lakshmi Vilas Bank">
                            Lakshmi Vilas Bank
                          </option>
                          <option value="Madhya Pradesh Gramin Bank">
                            Madhya Pradesh Gramin Bank
                          </option>
                          <option value="Madhyanchal Gramin Bank">
                            Madhyanchal Gramin Bank
                          </option>
                          <option value="Maharashtra Gramin Bank">
                            Maharashtra Gramin Bank
                          </option>
                          <option value="Manipur Rural Bank">
                            Manipur Rural Bank
                          </option>
                          <option value="Meghalaya Rural Bank">
                            Meghalaya Rural Bank
                          </option>
                          <option value="Mizoram Rural Bank">
                            Mizoram Rural Bank
                          </option>
                          <option value="Nainital Bank">Nainital Bank</option>
                          <option value="Nagaland Rural Bank">
                            Nagaland Rural Bank
                          </option>
                          <option value="NKGSB Co-Op Bank Ltd">
                            NKGSB Co-Op Bank Ltd
                          </option>
                          <option value="North East Small Finance Bank">
                            North East Small Finance Bank
                          </option>
                          <option value="Odisha Gramya Bank">
                            Odisha Gramya Bank
                          </option>
                          <option value="Paytm Payments Bank">
                            Paytm Payments Bank
                          </option>
                          <option value="Punjab &amp; Sind Bank">
                            Punjab &amp; Sind Bank
                          </option>
                          <option value="Punjab National Bank">
                            Punjab National Bank
                          </option>
                          <option value="Paschim Banga Gramin Bank">
                            Paschim Banga Gramin Bank
                          </option>
                          <option value="Prathama UP Gramin Bank">
                            Prathama UP Gramin Bank
                          </option>
                          <option value="Puduvai Bharathiar Grama Bank">
                            Puduvai Bharathiar Grama Bank
                          </option>
                          <option value="Punjab Gramin Bank">
                            Punjab Gramin Bank
                          </option>
                          <option value="Purvanchal Gramin Bank">
                            Purvanchal Gramin Bank
                          </option>
                          <option value="RBL Bank">RBL Bank</option>
                          <option value="Rajasthan Marudhara Gramin Bank">
                            Rajasthan Marudhara Gramin Bank
                          </option>
                          <option value="State Bank of Bikaner & Jaipur">
                            State Bank of Bikaner & Jaipur
                          </option>
                          <option value="State Bank of India">
                            State Bank of India
                          </option>
                          <option value="NSDL Payment Bank">
                            NSDL Payment Bank
                          </option>
                          <option value="Bharat Co-Operative Bank">
                            Bharat Co-Operative Bank
                          </option>
                          <option value="Saptagiri Grameena Bank">
                            Saptagiri Grameena Bank
                          </option>
                          <option value="Sarva Haryana Gramin Bank">
                            Sarva Haryana Gramin Bank
                          </option>
                          <option value="Saraswat Co-Operative Bank Ltd">
                            Saraswat Co-Operative Bank Ltd
                          </option>
                          <option value="Madhya Bihar Gramin Bank">
                            Madhya Bihar Gramin Bank
                          </option>
                          <option value="Saurashtra Gramin Bank">
                            Saurashtra Gramin Bank
                          </option>
                          <option value="South Indian Bank">
                            South Indian Bank
                          </option>
                          <option value="Suryoday Small Finance Bank">
                            Suryoday Small Finance Bank
                          </option>
                          <option value="Shivalik Small Finance Bank">
                            Shivalik Small Finance Bank
                          </option>
                          <option value="SVC Co-Operative Bank Ltd">
                            SVC Co-Operative Bank Ltd
                          </option>
                          <option value="Tamilnad Mercantile Bank">
                            Tamilnad Mercantile Bank
                          </option>
                          <option value="Tamil Nadu Grama Bank">
                            Tamil Nadu Grama Bank
                          </option>
                          <option value="Telangana Grameena Bank">
                            Telangana Grameena Bank
                          </option>
                          <option value="The Krur Vysya Bank Limited">
                            The Krur Vysya Bank Limited
                          </option>
                          <option value="THE COSMOS CO-OPERATIVE BANK LTD">
                            THE COSMOS CO-OPERATIVE BANK LTD
                          </option>
                          <option value="Tripura Gramin Bank">
                            Tripura Gramin Bank
                          </option>
                          <option value="UCO Bank">UCO Bank</option>
                          <option value="Union Bank of India">
                            Union Bank of India
                          </option>
                          <option value="Unity Small Finance Bank">
                            Unity Small Finance Bank
                          </option>
                          <option value="Utkal Grameen bank">
                            Utkal Grameen bank
                          </option>
                          <option value="Uttar Bihar Gramin Bank">
                            Uttar Bihar Gramin Bank
                          </option>
                          <option value="Uttarakhand Gramin Bank">
                            Uttarakhand Gramin Bank
                          </option>
                          <option value="Uttarbanga Kshetriya Gramin Bank">
                            Uttarbanga Kshetriya Gramin Bank
                          </option>
                          <option value="Utkarsh Small Finance Bank">
                            Utkarsh Small Finance Bank
                          </option>
                          <option value="Ujjivan Small Finance Bank">
                            Ujjivan Small Finance Bank
                          </option>
                          <option value="Vidharbha Konkan Gramin Bank">
                            Vidharbha Konkan Gramin Bank
                          </option>
                          <option value="YES Bank">YES Bank</option>
                        </select>
                        <span
                          className="field-validation-valid errormsgr"
                          data-valmsg-for="BankName"
                          data-valmsg-replace="true"
                          style={{ color: "red" }}
                        ></span>
                      </div>
                      {errors.bankName && (
                        <div className="error-message">{errors.bankName}</div>
                      )}
                    </div>
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label>Account Number</label>
                        <input
                          autoComplete="nope"
                          className="form-control"
                          data-val="true"
                          data-val-required="Account Number Required"
                          id="txtacntno"
                          maxLength="25"
                          name="AccountNumber"
                          placeholder="Account Number"
                          type="text"
                          value={accountNo}
                          onChange={(event) => {
                            const input = event.target.value;
                            if (/^\d*$/.test(input)) {
                              setAccountNo(input);
                            }
                          }}
                        />
                      </div>
                      {errors.accountNo && (
                        <div className="error-message">{errors.accountNo}</div>
                      )}
                    </div>
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label>Branch Name</label>
                        <input
                          id=""
                          name=""
                          className="form-control"
                          placeholder="Branch Name"
                          type="text"
                          maxLength="50"
                          value={branch}
                          onChange={(event) => setBranch(event.target.value)}
                        />
                      </div>
                      {errors.branch && (
                        <div className="error-message">{errors.branch}</div>
                      )}
                    </div>
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label>IFSC Code</label>
                        <input
                          autoComplete="nope"
                          className="form-control"
                          maxLength="11"
                          minLength="11"
                          placeholder="IFSC Code"
                          type="text"
                          value={ifsc}
                          onChange={(event) =>
                            setIfsc(event.target.value.toUpperCase())
                          }
                        />
                      </div>
                      {errors.ifsc && (
                        <div className="error-message">{errors.ifsc}</div>
                      )}
                    </div>
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label>Upload Cheque book or Passbook * </label>
                        {cancelChk !== "" ? (
                          <Link to={cancelChk} target="_blank">
                            <span>
                              <i className="fa fa-eye">View</i>
                            </span>
                          </Link>
                        ) : (
                          ""
                        )}
                        <input
                          className="form-control"
                          id=""
                          name=""
                          accept=".jpg, .jpeg, .png, .svg"
                          type="file"
                          onChange={handleCheque}
                        />
                      </div>
                      {errors.cancelChk && (
                        <div className="error-message">{errors.cancelChk}</div>
                      )}
                    </div>
                  </div>
                  {/* ) : ( */}

                  <div className="col-md-12 col-xs-12">
                    <h4 className="midhead">UPI Details </h4>
                  </div>

                  <div className=" col-md-12 col-xs-12" id="upidetails">
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label>UPI ID </label>
                        <input
                          autoComplete="nope"
                          className="form-control"
                          placeholder="UPI ID"
                          type="text"
                          value={upiId}
                          onChange={(event) => setUpiId(event.target.value)}
                        />
                      </div>
                      {/* {errors.upiId && (
                          <div className="error-message">{errors.upiId}</div>
                        )} */}
                    </div>
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label>UPI ID Screenshot </label>
                        <input
                          className="form-control"
                          id=""
                          type="file"
                          accept=".jpg, .jpeg, .png, .svg"
                          onChange={handleUpiIdScreen}
                        />
                      </div>
                      {/* {errors.upiIdScreen && (
                          <div className="error-message">
                            {errors.upiIdScreen}
                          </div>
                        )} */}
                    </div>
                  </div>
                  {/* )} */}
                  <div className="col-md-12 col-xs-12">
                    <h4 className="midhead">
                      Influencer Professional Information{" "}
                    </h4>
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Select Profession </label>
                      <select
                        className="form-control2"
                        style={{ alignItem: "center" }}
                        value={profession}
                        onChange={handleProfession}
                      >
                        <option value="">Select Profession</option>
                        <option value="carpenter">Carpenter</option>
                        <option value="contractor">Contractor</option>
                      </select>
                    </div>
                  </div>
                  {profession === "carpenter" ? (
                    <div className="col-md-3 col-sm-12 col-xs-12 carpenternumber">
                      <div className="form-group has-float-label style-two">
                        <label>Contractor Mobile No. </label>
                        <input
                          autoComplete="nope"
                          className="form-control"
                          maxLength="10"
                          minLength="10"
                          name=""
                          placeholder="Contractor Mobile No."
                          type="text"
                          value={contractorMobile}
                          onChange={(event) => {
                            const input = event.target.value;
                            if (/^\d*$/.test(input)) {
                              setContractorMobile(input);
                            }
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* <div className="col-md-3 col-sm-12 col-xs-12 teamsize">
                                        <div className="form-group has-float-label style-two">
                                            <label>Team Size </label>
                                            <input autoComplete="nope" className="form-control" id="" maxLength="5" name="" placeholder="Team Size" type="text" value={teamsize} onChange={(event) => {
                                                const input = event.target.value;
                                                if (/^\d*$/.test(input)) {
                                                    setTeamsize(input);
                                                }
                                            }} />
                                        </div>
                                    </div> */}
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>No. of Supervisors </label>
                      <input
                        autoComplete="nope"
                        className="form-control"
                        id=""
                        maxLength="5"
                        name=""
                        placeholder="No. of Supervisors"
                        type="text"
                        value={noOfSupervisor}
                        onChange={(event) => {
                          const input = event.target.value;
                          if (/^\d*$/.test(input)) {
                            setNoOfSupervisor(input);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Annual Yearly Business (Rs in Lakhs) </label>
                      <input
                        autoComplete="nope"
                        className="form-control"
                        id=""
                        maxLength="5"
                        name=""
                        placeholder="Annual Yearly Business (Rs in Lakhs)"
                        type="text"
                        value={annualYearly}
                        onChange={(event) => {
                          const input = event.target.value;
                          if (/^\d*$/.test(input)) {
                            setAnnualYearly(input);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Avg. No. of Sites Handled Yearly </label>
                      <input
                        autoComplete="nope"
                        className="form-control"
                        id=""
                        maxLength="5"
                        name=""
                        placeholder="Avg No. of Sites Handled Yearly"
                        type="text"
                        value={avgNoSite}
                        onChange={(event) => {
                          const input = event.target.value;
                          if (/^\d*$/.test(input)) {
                            setAvgNoSite(input);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Major Brands Used</label>
                      <div>
                        <div className="row">
                          <div className="col-md-7 justify-content-between d-flex">
                            <label className="checkbox-inline">
                              <input
                                id=""
                                type="checkbox"
                                checked={
                                  brand.includes("Hettich") ? true : false
                                }
                                onChange={() => addtoBrand("Hettich")}
                              />
                              Hettich
                            </label>
                            <label className="checkbox-inline">
                              <input
                                id=""
                                type="checkbox"
                                checked={brand.includes("Ebco") ? true : false}
                                onChange={() => addtoBrand("Ebco")}
                              />
                              Ebco
                            </label>
                            <label className="checkbox-inline">
                              <input
                                id=""
                                type="checkbox"
                                checked={
                                  brand.includes("Godrej") ? true : false
                                }
                                onChange={() => addtoBrand("Godrej")}
                              />
                              Godrej
                            </label>
                            <label className="checkbox-inline">
                              <input
                                id=""
                                type="checkbox"
                                checked={
                                  brand.includes("Dorset") ? true : false
                                }
                                onChange={() => addtoBrand("Dorset")}
                              />
                              Dorset
                            </label>
                            <label className="checkbox-inline">
                              <input
                                id=""
                                type="checkbox"
                                checked={
                                  brand.includes("Enox/Ozone") ? true : false
                                }
                                onChange={() => addtoBrand("Enox/Ozone")}
                              />
                              Enox/Ozone
                            </label>
                            <label className="checkbox-inline">
                              <input
                                id=""
                                type="checkbox"
                                checked={brand.includes("Yale") ? true : false}
                                onChange={() => addtoBrand("Yale")}
                              />
                              Yale{" "}
                            </label>
                            <label className="checkbox-inline">
                              <input
                                id=""
                                type="checkbox"
                                checked={brand.includes("Dorma") ? true : false}
                                onChange={() => addtoBrand("Dorma")}
                              />
                              Dorma
                            </label>
                            <label className="checkbox-inline">
                              <input
                                id=""
                                type="checkbox"
                                checked={
                                  brand.some((brands) =>
                                    brands.startsWith("Others")
                                  )
                                    ? true
                                    : false
                                }
                                onChange={() => addtoBrand("Others")}
                              />
                              Others
                            </label>
                          </div>
                          {brand.some((brands) =>
                            brands.startsWith("Others")
                          ) ? (
                            <div className="col-md-3 ">
                              <input
                                type="text"
                                className="form-control"
                                value={otherBrand}
                                onChange={(event) =>
                                  setOtherValue(event.target.value)
                                }
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>
                        Type and Value of Products Used on Annual Basis
                      </label>
                      <div className="type_and_value">
                        {productValue.map((value, index) => (
                          <label className="checkbox-inline">
                            <input
                              id=""
                              type="checkbox"
                              checked={
                                product.includes(p[index]) ? true : false
                              }
                              onChange={() => addtoProduct(p[index])}
                            />
                            {p[index]}

                            <input
                              autoComplete="nope"
                              className="form-control"
                              id=""
                              maxLength="5"
                              key={index}
                              type="text"
                              value={value}
                              onChange={(e) =>
                                handleInputChange(index, e.target.value)
                              }
                            />
                          </label>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Type of Projects </label>
                      <select
                        className="form-control2"
                        value={typeofProjects}
                        onChange={(event) =>
                          setTypeofProjects(event.target.value)
                        }
                        style={{ alignItem: "center" }}
                      >
                        <option value="">Select Type of projects</option>
                        <option value="Home interiors">Home interiors</option>
                        <option value="Commercial">Commercial</option>
                        <option value="Home interiors,Commercial">Both</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Solution Category (Does Turnkey Project)</label>
                      <div>
                        <label className="checkbox-inline">
                          <input
                            type="checkbox"
                            checked={
                              category.includes("Complete Kitchen")
                                ? true
                                : false
                            }
                            onChange={() => addtoCategory("Complete Kitchen")}
                          />
                          Complete Kitchen
                        </label>
                        <label className="checkbox-inline">
                          <input
                            type="checkbox"
                            checked={
                              category.includes("Wardrobe units") ? true : false
                            }
                            onChange={() => addtoCategory("Wardrobe units")}
                          />
                          Wardrobe units{" "}
                        </label>
                        <label className="checkbox-inline">
                          <input
                            type="checkbox"
                            checked={category.includes("Doors") ? true : false}
                            onChange={() => addtoCategory("Doors")}
                          />
                          Doors
                        </label>
                        <label className="checkbox-inline">
                          <input
                            type="checkbox"
                            checked={
                              category.includes("Fabricator-GF") ? true : false
                            }
                            onChange={() => addtoCategory("Fabricator-GF")}
                          />
                          Fabricator-GF
                        </label>
                        <label className="checkbox-inline">
                          <input
                            type="checkbox"
                            checked={
                              category.includes("Shower enclosure")
                                ? true
                                : false
                            }
                            onChange={() => addtoCategory("Shower enclosure")}
                          />
                          Shower enclosure
                        </label>
                        <label className="checkbox-inline">
                          <input
                            type="checkbox"
                            checked={
                              category.includes("Glass partition and solution")
                                ? true
                                : false
                            }
                            onChange={() =>
                              addtoCategory("Glass partition and solution")
                            }
                          />
                          Glass partition and solution
                        </label>
                        <label className="checkbox-inline">
                          <input
                            type="checkbox"
                            checked={
                              category.includes("Turnkey") ? true : false
                            }
                            onChange={() => addtoCategory("Turnkey")}
                          />
                          Turnkey
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Top 3 Dealers / Retailers Buy From </label>
                      <div className="row">
                        <div className="col-md-3 col-xs-12">
                          <input
                            autoComplete="nope"
                            className="form-control"
                            id=""
                            maxLength="35"
                            name="Dealer1"
                            placeholder="Dealer #1"
                            type="text"
                            value={dealer1}
                            onChange={(event) => {
                              const input = event.target.value;
                              if (/^[a-zA-Z\s]*$/.test(input)) {
                                // Only alphabetic characters
                                setDealer1(input);
                              }
                            }}
                          />
                        </div>
                        <div className="col-md-3 col-xs-12">
                          <input
                            autoComplete="nope"
                            className="form-control"
                            id=""
                            maxLength="35"
                            name="Dealer2"
                            placeholder="Dealer #2"
                            type="text"
                            value={dealer2}
                            onChange={(event) => {
                              const input = event.target.value;
                              if (/^[a-zA-Z\s]*$/.test(input)) {
                                setDealer2(input);
                              }
                            }}
                          />
                        </div>
                        <div className="col-md-3 col-xs-12">
                          <input
                            autoComplete="nope"
                            className="form-control"
                            id=""
                            maxLength="35"
                            name="Dealer3"
                            placeholder="Dealer #3"
                            type="text"
                            value={dealer3}
                            onChange={(event) => {
                              const input = event.target.value;
                              if (/^[a-zA-Z\s]*$/.test(input)) {
                                setDealer3(input);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label> If Sub Dealer, Mention Sub Dealer Name</label>
                      <input
                        autoComplete="nope"
                        className="form-control"
                        id=""
                        maxLength="35"
                        placeholder="Subdealer Name"
                        type="text"
                        value={subDealerName}
                        onChange={(event) =>
                          setSubDealerName(event.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xs-12 mb-15">
                    <div className="text-center">
                      <input
                        id="btnsubmit"
                        className="btn login_btn"
                        type="submit"
                        value="Submit"
                        onClick={register}
                      />
                      <input
                        id="btndraft"
                        className="btn login_btn11 cancel"
                        style={{ width: "139px", height: "35px" }}
                        type="submit"
                        value="Save as Draft"
                        onClick={saveAsDraft}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Link
        id="scrollUp"
        to=""
        className={`scroll-to-top-button ${isVisible ? "visible" : "hidden"}`}
        style={{ position: "fixed", zIndex: "2147483647" }}
        onClick={scrollToTop}
      >
        <FontAwesomeIcon icon={faChevronUp} />
      </Link>
    </>
  );
}
