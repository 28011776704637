import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import HomeAboutHafele from "./home/HomeAboutHafele";
import HomeAboutProgram from "./home/HomeAboutProgram";
import HomeContact from "./home/HomeContact";
// import VerifyTicket from './manage ticket/VerifyTicket'
import Dashboard from "./Dashboard";
import Registration from "./Registration";
import PendingReg from "./PendingReg";
import HierarchyLogin from "./HierarchyLogin";
import ApprovedReg from "./ApprovedReg";
import RejectedReg from "./RejectedReg";
import RegistraionApproved from "./RegistraionApproved";
import TicketReport from "./manage ticket/TicketReport";
import RegistraionApproval from "./RegistraionApproval";
import RptAnomaly from "./reports/RptAnomaly";
import RptEnrollment from "./reports/RptEnrollment";
import RptWarehouse from "./reports/RptWarehouse";
import RptSalesHierarchy from "./reports/RptSalesHierarchy";
import RptArticles from "./reports/RptArticles";
import RptSalesDistrictMap from "./reports/RptSalesDistrictMap";
import RptRegisteredUsers from "./reports/RptRegisteredUsers";
import RptGeneratedCoupon from "./RptGeneratedCoupon";
import RptLogin from "./reports/RptLogin";
import RptOtpSms from "./reports/RptOtpSms";
import RptProductWise from "./reports/RptProductWise";
import RptQrError from "./reports/RptQrError";
import RptRedemption from "./reports/RptRedemption";
import SalesProtected from "./SalesProtected";
import Page_404 from "./Page_404";
import VerifyAnomaly from "./VerifyAnomaly";
import ForgotPassword from "./ForgotPassword";
import LandingPage from "./LandingPage";
import RMHeader from "./common/RMHeader";
import AMHeader from "./common/AMHeader";
import ChangePassword from "./ChangePassword";
import TermsAndConitions from "./TermsAndConitions";

import EnglishTnc from "./appTnc/English";
import EnglishFaq from "./appFaq/English";
import WhatsNew from "./appWhatsNew/WhatsNew";
import Hindi from "./appTnc/Hindi";

export default function App() {
  localStorage.setItem("language", "en");

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about_hafele" element={<HomeAboutHafele />}></Route>
          <Route path="/about_program" element={<HomeAboutProgram />}></Route>
          <Route path="/contact" element={<HomeContact />}></Route>
          {/* <Route path='/verify_ticket' element={<SalesProtected Cmp={VerifyTicket} />}></Route> */}
          {/* <Route path='/dashboard' element={<SalesProtected Cmp={Dashboard} requiredRoles={['SALES', 'SH', 'RO', 'PRD', 'HO']} />}></Route> */}
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route
            path="/registration"
            element={
              <SalesProtected Cmp={Registration} requiredRoles={["SALES"]} />
            }
          ></Route>
          <Route
            path="/pending_reg"
            element={
              <SalesProtected Cmp={PendingReg} requiredRoles={["SALES"]} />
            }
          ></Route>
          <Route
            path="/verify_anomaly"
            element={
              <SalesProtected
                Cmp={VerifyAnomaly}
                requiredRoles={["SALES", "SH"]}
              />
            }
          ></Route>
          <Route
            path="/approved_reg"
            element={
              <SalesProtected Cmp={ApprovedReg} requiredRoles={["SALES"]} />
            }
          ></Route>
          <Route
            path="/rejected_reg"
            element={
              <SalesProtected Cmp={RejectedReg} requiredRoles={["SALES"]} />
            }
          ></Route>
          <Route
            path="/registration_approved"
            element={<RegistraionApproved />}
          ></Route>
          {/* <Route path='/registration_approved' element={<SalesProtected Cmp={RegistraionApproved} requiredRoles={['SALES', 'SH', 'RO', 'HO']} />}></Route> */}
          {/* <Route path='/ticket_report' element={<SalesProtected Cmp={TicketReport} requiredRoles={['SALES']} />}></Route> */}
          <Route path="/ticket_report" element={<TicketReport />}></Route>
          <Route
            path="/registration_approval"
            element={
              <SalesProtected
                Cmp={RegistraionApproval}
                requiredRoles={["SH"]}
              />
            }
          ></Route>
          {/* <Route path='/rpt_login' element={<SalesProtected Cmp={RptLogin} requiredRoles={['SALES', 'SH', 'RO']} />}></Route> */}
          <Route path="/rpt_login" element={<RptLogin />}></Route>
          {/* <Route path='/rpt_enrollment' element={<SalesProtected Cmp={RptEnrollment} requiredRoles={['SALES', 'SH', 'RO']} />}></Route> */}
          <Route path="/rpt_enrollment" element={<RptEnrollment />}></Route>
          <Route path="/rpt_warehouse" element={<RptWarehouse />}></Route>
          <Route
            path="/rpt_sales_hierarchy"
            element={<RptSalesHierarchy />}
          ></Route>
          <Route path="/rpt_articles" element={<RptArticles />}></Route>
          <Route
            path="/rpt_sales_district_map"
            element={<RptSalesDistrictMap />}
          ></Route>
          <Route
            path="/rpt_registered_users"
            element={<RptRegisteredUsers />}
          ></Route>
          {/* <Route path='/rpt_product_wise' element={<SalesProtected Cmp={RptProductWise} requiredRoles={['SALES', 'SH', 'RO']} />}></Route> */}
          <Route path="/rpt_product_wise" element={<RptProductWise />}></Route>
          {/* <Route path='/rpt_redemption' element={<SalesProtected Cmp={RptRedemption} requiredRoles={['SALES', 'SH', 'RO']} />}></Route> */}
          <Route path="/rpt_redemption" element={<RptRedemption />}></Route>
          {/* <Route path='/rpt_otp_sms' element={<SalesProtected Cmp={RptOtpSms} requiredRoles={['SALES', 'SH', 'RO']} />}></Route> */}
          <Route path="/rpt_otp_sms" element={<RptOtpSms />}></Route>
          {/* <Route path='/rpt_qr_error' element={<SalesProtected Cmp={RptQrError} requiredRoles={['SALES', 'SH', 'RO']} />}></Route> */}
          <Route path="/rpt_qr_error" element={<RptQrError />}></Route>
          <Route path="/rpt_anomaly" element={<RptAnomaly />}></Route>
          {/* <Route path='/rpt_anomaly' element={<SalesProtected Cmp={RptAnomaly} requiredRoles={['SALES', 'SH', 'RO']} />}></Route> */}
          <Route
            path="/rpt_generated_coupon"
            element={<RptGeneratedCoupon />}
          ></Route>
          {/* <Route path='/rpt_generated_coupon' element={<SalesProtected Cmp={RptGeneratedCoupon} requiredRoles={['SALES', 'SH', 'RO']} />}></Route> */}
          <Route path="/forgot_password" element={<ForgotPassword />}></Route>
          <Route path="/*" element={<Page_404 />}></Route>
          <Route path="/landing" element={<LandingPage />}></Route>
          <Route path="/rmheader" element={<RMHeader />}></Route>
          <Route path="/amheader" element={<AMHeader />}></Route>
          <Route path="/changePassword" element={<ChangePassword />}></Route>
          <Route
            path="/TermsAndConitions"
            element={<TermsAndConitions />}
          ></Route>

          <Route path="/app/tnc" element={<EnglishTnc />}></Route>
          <Route path="/app/tnc-hindi" element={<Hindi />}></Route>
          <Route path="/app/faq" element={<EnglishFaq />}></Route>
          <Route path="/app/whatsnew" element={<WhatsNew />}></Route>
          <Route path="/hierarchy_login" element={<HierarchyLogin />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
