import React from "react";
import logo2 from "../../assets/img/logo/logo2.png";
const Hindi = () => {
  return (
    <>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-2 col-sm-12">
              <div className="logo logo1">
                <img src={logo2} alt="" width={"100px"} height={"111px"} />
              </div>
            </div>
            <div className="col-md-10 col-sm-12"></div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="" style={{ width: "100%" }}>
                <h3>सामान्य शर्तें और नियम</h3>
                <ol class="list-terms">
                  <li>
                    कृपया HAFELE INDIA PRIVATE LIMITED द्वारा प्रदान की गई
                    Hafele Contractor Unnati Programme ऐप का उपयोग करने या
                    एक्सेस करने से पहले इन शर्तों और नियमों को ध्यान से पढ़ें।
                    Hafele Contractor Unnati Programme ऐप का उपयोग या एक्सेस
                    करके, आप अपनी स्वतंत्र सहमति प्रदान करते हैं, स्वीकार करते
                    हैं और यह सहमति देते हैं कि आपको इन शर्तों और नियमों के
                    अनुसार HAFELE Contractor Unnati Programme स्कीम का एक्सेस और
                    उपयोग करने की अनुमति है। आपको इन शर्तों और नियमों को ध्यान
                    से पढ़ना चाहिए ताकि आप HAFELE Contractor Unnati Programme
                    स्कीम का लाभ उठाने से पहले इन्हें समझ सकें।
                  </li>
                  <br />
                  <li>
                    {" "}
                    ये शर्तें और नियम (जिन्हें आगे ‘शर्तें’ के रूप में संदर्भित
                    किया जाएगा) के तहत Hafele Contractor Unnati Programme (जिसे
                    आगे ‘कार्यक्रम’ के रूप में संदर्भित किया जाएगा) को आपके लिए
                    उपलब्ध कराया जाता है, जैसे कि नीचे उल्लेखित किया गया है।
                    कार्यक्रम में आपकी भागीदारी, या इसके कोई भी हिस्सा, यह मान
                    लिया जाएगा कि आप इन शर्तों से बंधने की अपनी इच्छा को दर्शाते
                    हैं।
                  </li>
                  <br />
                  <li>
                    {" "}
                    कृपया ध्यान दें कि यह कार्यक्रम सभी ठेकेदारों, बढ़ई,
                    इलेक्ट्रिशियन (लाइटिंग के मामले में) और प्लंबर (सैनिटरी
                    समाधान के मामले में), जिन्हें आगे ‘प्रभावक’ कहा जाएगा, के
                    लिए उपलब्ध कराया जा रहा है और यह कार्यक्रम 1 मार्च 2022 से
                    शुरू होने वाली कार्यक्रम की अवधि तक लागू है।
                  </li>
                  <br />
                  <li>
                    {" "}
                    HAFELE INDIA PRIVATE LIMITED को बिना पूर्व सूचना के इस
                    कार्यक्रम के पूरे या किसी भी हिस्से को वापस लेने, निलंबित
                    करने, रद्द करने या संशोधित करने का अधिकार है। इस कार्यक्रम
                    के किसी भी हिस्से या उससे प्राप्त लाभ को किसी भी संविदात्मक
                    संबंध के उद्देश्य के रूप में 'विचार' के रूप में नहीं लिया
                    जाएगा।
                  </li>
                  <br />
                  <li>
                    {" "}
                    इस कार्यक्रम में भागीदारी केवल प्रभावकों और HAFELE द्वारा
                    पंजीकरण और KYC प्रक्रिया को सफलतापूर्वक पूरा करने के माध्यम
                    से की जाती है। सफल पंजीकरण प्राप्त करने पर, प्रभावक इस
                    कार्यक्रम के तहत सदस्य बन जाएंगे। यह कार्यक्रम अपने पंजीकृत
                    सदस्यों को QR कोड स्कैन करने पर लॉयल्टी प्वाइंट्स कमाने का
                    अधिकार देता है, जिन्हें बैंक खाता ट्रांसफर या रिवॉर्ड कैटलॉग
                    के माध्यम से रिडीम किया जा सकता है, जो HAFELE द्वारा समय-समय
                    पर कार्यक्रम के लिए अधिसूचित रिडेम्पशन पॉलिसी द्वारा
                    नियंत्रित होगा।
                  </li>
                  <br />
                  <li>
                    {" "}
                    रिडेम्पशन पॉलिसी और अन्य शर्तें जो समय-समय पर (कार्यक्रम के
                    दौरान) अधिसूचित की जाएंगी, हमेशा इन शर्तों और नियमों के साथ
                    पढ़ी जाएंगी और कार्यक्रम की शर्तों और नियमों के साथ लागू
                    होंगी।
                  </li>
                  <br />
                  <li>
                    यह कार्यक्रम पूरे भारत में लागू है, लेकिन यह तमिलनाडु
                    पुरस्कार योजनाओं (प्रतिबंध) अधिनियम-1979 के अधीन है और
                    निम्नलिखित बिंदु विशेष रूप से तमिलनाडु राज्य के प्रभावकों के
                    लिए लागू हैं:
                    <ul class="underlist">
                      <li>
                        a)इस कार्यक्रम के तहत किसी भी लाभ या योजना जो किसी टिकट,
                        लॉट, संख्या या अंक के ड्रॉ द्वारा निर्धारित की जाती है,
                        तो यह तमिलनाडु राज्य में लागू नहीं होगी।
                      </li>
                      <li>
                        b) इस कार्यक्रम के तहत जो भी लाभ या योजना तमिलनाडु
                        पुरस्कार योजनाओं (प्रतिबंध) अधिनियम-1979 के अनुपालन में
                        नहीं हैं, वे तमिलनाडु राज्य में रहने वाले प्रभावकों के
                        लिए लागू नहीं होंगी।
                      </li>
                      <li>
                        c) तमिलनाडु राज्य में, प्रत्येक उत्पाद के लिए निर्धारित
                        बेस रिवॉर्ड प्वाइंट्स की मूल्य सदस्य के खाते में उस
                        प्रत्येक HAFELE उत्पाद की खरीद पर QR कोड को सफलतापूर्वक
                        स्कैन करने पर क्रेडिट की जाएगी।
                      </li>
                      <li>
                        d) तमिलनाडु राज्य के लिए केवल बेस प्वाइंट योजना लागू है,
                        जिसमें प्रत्येक QR कोड स्कैन पर सभी प्रभावकों के खाते
                        में समान रिवॉर्ड प्वाइंट्स क्रेडिट किए जाएंगे, और इस बेस
                        प्वाइंट योजना में कोई लॉटरी, लकी ड्रॉ, संख्या या अंक
                        शामिल नहीं होगा। इसके अतिरिक्त, भूगोल बूस्टर, टियर
                        स्कीम, एनरोलमेंट बोनस आदि जैसी अन्य ऑफ़र तमिलनाडु राज्य
                        में इस कार्यक्रम के तहत लागू नहीं हैं।
                      </li>
                      <li>
                        e) तमिलनाडु राज्य में, विभिन्न उत्पादों के लिए अलग-अलग
                        रिवॉर्ड प्वाइंट्स होते हैं, लेकिन एक ही श्रेणी के एकल
                        उत्पाद पर प्रत्येक स्कैन के खिलाफ समान रिवॉर्ड प्वाइंट्स
                        दिए जाएंगे।
                      </li>
                    </ul>
                  </li>
                  <br />
                  <li>
                    HAFELE को अपनी पूरी विवेकाधिकार पर किसी भी आवेदन को अपने
                    सदस्य के रूप में पंजीकरण से अस्वीकार करने का अधिकार है, बिना
                    किसी कारण बताए।
                  </li>
                  <br />
                  <li>
                    पंजीकरण के बाद भी, यदि कभी यह पाया जाता है कि आवेदक द्वारा
                    दी गई जानकारी अधूरी, गलत, झूठी और/या नकली है, तो HAFELE के
                    पास पंजीकृत सदस्य (प्रभावक) का पंजीकरण रद्द/रोकने का अधिकार
                    है, बिना किसी कारण बताए।
                  </li>
                  <br />
                  <li>
                    {" "}
                    HAFELE किसी भी नुकसान, क्षति, या गलत-फिल्ड फॉर्म्स के लिए
                    जिम्मेदार नहीं होगा। पंजीकरण फॉर्म में दी गई जानकारी के लिए
                    सदस्य ही पूरी तरह से जिम्मेदार होगा।
                  </li>
                  <br />
                  <li>
                    HAFELE इस कार्यक्रम के तहत प्रभावक द्वारा की गई किसी भी नकली
                    या काल्पनिक प्रविष्टि/पंजीकरण के लिए जिम्मेदार नहीं होगा, और
                    HAFELE प्रभावकों की प्रमाणिकता या प्रमाण पत्रों की जांच करने
                    का भी उत्तरदायी नहीं होगा। धोखाधड़ी या अनुचित उपयोग से
                    संबंधित कोई भी समस्या HAFELE और/या इसके पेशेवर एजेंसी द्वारा
                    जांची नहीं जाएगी, हालांकि HAFELE को इस संबंध में कोई शिकायत
                    प्राप्त होने पर किसी भी पंजीकरण को निलंबित या समाप्त करने का
                    अधिकार होगा।
                  </li>
                  <br />
                  <li>
                    कार्यक्रम में पंजीकरण तभी पूरा होगा जब पंजीकरण फॉर्म में
                    उल्लिखित सभी दस्तावेज़ पूरी तरह से सही रूप में प्रस्तुत किए
                    गए हों। कार्यक्रम के तहत दस्तावेज़ों में कोई दोष या
                    अनुपलब्धता प्रभावक को अयोग्य ठहरा सकती है या निलंबन,
                    रद्दीकरण और पंजीकरण निरस्त कर सकती है, जैसा कि HAFELE द्वारा
                    निर्धारित किया जाएगा।
                  </li>
                  <br />
                  <li>
                    {" "}
                    13. डेटा की गोपनीयता और उपयोग डेटा की गोपनीयता को बनाए रखा
                    जाएगा, और HAFELE अपनी पूरी कोशिश करेगा कि तकनीकी और
                    व्यावहारिक रूप से संभव और उचित तरीके से सदस्य के डेटाबेस की
                    गोपनीयता की रक्षा की जाए। डेटा सुरक्षा के लिए आप हमारी
                    वेबसाइट पर
                    जा सकते हैं।
                  </li>
                  <br />
                  <li>
                    {" "}
                    HAFELE लॉयल्टी पॉइंट्स वह कुल क्रेडिट पॉइंट्स हैं जो आप
                    HAFELE उत्पादों की खरीदारी के आधार पर एक HAFELE प्रभावक के
                    रूप में इकट्ठा कर सकते हैं। यह HAFELE द्वारा समय-समय पर अपनी
                    विवेकाधिकार पर निर्धारित विधि/योजना के तहत होगा।
                  </li>
                  <br />
                  <li>
                    {" "}
                    HAFELE लॉयल्टी पॉइंट्स को इस कार्यक्रम के तहत निर्धारित विधि
                    के अलावा किसी अन्य तरीके से न तो भुनाया जा सकता है और न ही
                    रिडीम किया जा सकता है।
                  </li>
                  <br />
                  <li>
                    {" "}
                    यह स्पष्ट रूप से कहा गया है कि HAFELE लॉयल्टी पॉइंट्स न तो
                    एक मोल-भाव करने योग्य दस्तावेज़ है और न ही कोई विचार
                    (consideration) है, और इसके अंतर्गत या कहीं भी कुछ भी ऐसा
                    नहीं है जो आपको HAFELE INDIA PRIVATE LIMITED या इसके किसी भी
                    सहायक, उपसहायक, निदेशक, अधिकारी या कर्मचारी के खिलाफ किसी भी
                    HAFELE लॉयल्टी पॉइंट्स के मूल्यांकन, रिडीमेशन या लिक्विडेशन
                    के संबंध में दावा या कार्रवाई का अधिकार दे।
                  </li>
                  <br />
                  <li>
                    यदि किसी प्रभावक के खाते में कोई लॉयल्टी पॉइंट्स और/या
                    पुरस्कार/लाभ गलत तरीके से क्रेडिट हो जाते हैं, तो वही HAFELE
                    द्वारा डेबिट या रिवर्स किया जा सकता है और संबंधित प्रभावक को
                    इसके बारे में सूचित किया जाएगा, और इस संबंध में कोई भी
                    अनुरोध नहीं स्वीकार किया जाएगा।
                  </li>
                  <br />
                  <li>
                    {" "}
                    यदि किसी सदस्य को QR कोड स्कैन करते समय या कोड को मैन्युअली
                    दर्ज करते समय कोई कठिनाई या त्रुटि हो, तो वह सीधे कार्यक्रम
                    हेल्पडेस्क से संपर्क कर सकता है और कोड को
                    एजेंट/प्रतिनिधि/प्रबंधक को रिपोर्ट कर सकता है।
                  </li>
                  <br />
                  <li>
                    {" "}
                    यदि एक उपयोग किया हुआ QR कोड फिर से ऐप के माध्यम से स्कैन
                    किया जाता है, तो सिस्टम एक त्रुटि संदेश देगा और सदस्य को
                    HAFELE से ताज़े खरीदी गए उत्पाद का QR कोड स्कैन करना होगा।
                  </li>
                  <br />
                  <li>
                    {" "}
                    यदि पॉइंट्स और/या पुरस्कार/लाभ के संबंध में कोई विवाद
                    उत्पन्न होता है, तो इसे केवल आवश्यक सहायक लेन-देन
                    बिल/चालान/एमआरपी टैग के साथ HAFELE के कॉल सेंटर में प्रस्तुत
                    करने पर ही देखा और हल किया जाएगा। इस संबंध में HAFELE द्वारा
                    लिया गया निर्णय अंतिम होगा। इस संबंध में कोई भी अनुरोध
                    स्वीकार नहीं किया जाएगा।
                  </li>
                  <br />
                  <li>
                    {" "}
                    पॉइंट्स के रूपांतरण प्रतिशत में बिना पूर्व सूचना के कभी भी
                    बदलाव किया जा सकता है, और इसके लिए HAFELE जिम्मेदार नहीं
                    होगा।
                  </li>
                  <br />
                  <li>
                    आप निम्नलिखित तरीके से HAFELE लॉयल्टी पॉइंट्स जमा कर सकते
                    हैं:
                    <ul class="underlist">
                      <li>QR कोड्स को स्कैन करके</li>
                      <li>ऑनबोर्डिंग बोनस</li>
                      <li>टियर बोनस (यदि लागू हो)</li>
                      <li>मल्टीप्लायर बोनस</li>
                      <li>भूगोल बोनस</li>
                      <li>एंगेजमेंट बोनस (यदि लागू हो)</li>
                      <li>सेल्स ड्राइविंग बोनस (यदि लागू हो)</li>
                    </ul>
                  </li>
                  <br />
                  <li>
                    आप HAFELE लॉयल्टी पॉइंट्स को रिडेम्प्शन कैटलॉग के अनुसार
                    रिडीम कर सकते हैं, जो आपको सूचित किया जाएगा, और आप इसे ई-कैश
                    (अकाउंट ट्रांसफर) में भी बदल सकते हैं। कृपया ध्यान दें कि
                    HAFELE लॉयल्टी पॉइंट्स को किसी अन्य तरीके से रिडीम नहीं किया
                    जा सकता है, सिवाय इसके जो यहां विशेष रूप से उल्लेखित किया
                    गया है।
                  </li>
                  <br />
                  <li>
                    {" "}
                    HAFELE लॉयल्टी पॉइंट्स गैर-हस्तांतरणीय होते हैं और इन्हें
                    किसी अन्य व्यक्ति को सौंपा, बेचा या स्थानांतरित नहीं किया जा
                    सकता है।
                  </li>
                  <br />
                  <li>
                    HAFELE के पास किसी भी समय, बिना पूर्व सूचना के, किसी भी या
                    सभी प्रचारात्मक लाभों को वापस लेने का अधिकार है।
                  </li>
                  <br />
                  <li>
                    {" "}
                    स्वागत बोनस (एनरोलमेंट बोनस) उन सदस्यों को दिया जा सकता है
                    जो सफलतापूर्वक KYC पूरा करके कार्यक्रम में पंजीकरण करते हैं।
                    यह स्वागत बोनस एक बार का बोनस हो सकता है और इसे पुनः नहीं
                    दिया जाएगा।
                  </li>
                  <br />
                  <li>
                    {" "}
                    स्वागत बोनस पॉइंट्स को रिडीम किया जाएगा जब प्रभावक/सदस्य
                    रिडीमेशन के लिए निर्धारित न्यूनतम सीमा तक पहुंचेंगे, जैसा कि
                    HAFELE द्वारा परिभाषित किया गया है।
                  </li>
                  <br />
                  <li>
                    HAFELE INDIA PRIVATE LIMITED को HAFELE लॉयल्टी पॉइंट्स को
                    अर्जित करने या रिडीम करने के तरीके और रूप में किसी भी समय
                    बदलाव करने का अधिकार है। यह स्पष्ट किया जाता है कि, ऊपर बताए
                    गए सामान्य अधिकारों को सीमित किए बिना, HAFELE INDIA PRIVATE
                    LIMITED निम्नलिखित कर सकता है:
                    <ul class="underlist">
                      <li>
                        एक नई योजना और प्रचारात्मक ऑफर निर्धारित कर सकता है,
                        जिसमें HAFELE लॉयल्टी पॉइंट्स कमाने के अवसरों को एक
                        विशेष अवधि के लिए बढ़ाया या घटाया जा सकता है।
                      </li>
                      <li>
                        HAFELE लॉयल्टी पॉइंट्स रिडीमेशन के लिए उपलब्ध उपहारों को
                        बदल सकता है, प्रतिस्थापित कर सकता है या समाप्त कर सकता
                        है।
                      </li>
                    </ul>
                  </li>
                  <br />
                  <li>
                    {" "}
                    कार्यक्रम से उत्पन्न होने वाले किसी भी धोखाधड़ी या पुरस्कार
                    पॉइंट्स/लाभ के दुरुपयोग के मामले में HAFELE उचित प्रशासनिक
                    और/या कानूनी कार्रवाई कर सकता है, जिसमें जमा किए गए पॉइंट्स
                    और संबंधित पुरस्कार/लाभ की जब्ती शामिल हो सकती है, और इसके
                    परिणामस्वरूप सदस्यता का निलंबन या रद्दीकरण हो सकता है।
                  </li>
                  <br />
                  <li>
                    {" "}
                    यदि प्रभावक द्वारा प्रदान की गई सेवाएं उपयुक्त या संतोषजनक
                    नहीं होती हैं, तो HAFELE किसी भी प्रकार से जिम्मेदार नहीं
                    होगा, और HAFELE इस संबंध में प्रभावक की सेवाओं के लिए कोई
                    गारंटी नहीं देता है और न ही इसके बारे में कोई बयान करता है
                  </li>
                  <br />
                  <li>
                    {" "}
                    किसी भी प्रभावक द्वारा खरीदी गई कोई भी उत्पाद मानक उत्पाद
                    वारंटी शर्तों (यदि कोई हो) के अधीन होगी।
                  </li>
                  <br />
                  <li>
                    {" "}
                    HAFELE किसी भी प्रकार से प्रभावित नहीं होगा या कानूनी रूप से
                    किसी भी प्रभावक/सदस्य के लिए जिम्मेदार नहीं होगा यदि
                    कार्यक्रम को कानूनी प्रावधानों,
                    न्यायिक/क्वासी-ज्यूडिशियल/ट्रिब्यूनल/फोरम आदेशों या अन्य कोई
                    शक्ति अप्रत्याशित कारणों से उसके नियंत्रण से बाहर खींच लिया
                    जाता है या संशोधित/रद्द कर दिया जाता है।
                  </li>
                  <br />
                  <li>
                    {" "}
                    HAFELE किसी भी ऐसी स्थिति या परिस्थिति के लिए जिम्मेदार नहीं
                    होगा जो इसके उचित नियंत्रण या पहुंच के बाहर हो, और जो
                    पंजीकृत प्रभावक के अनुरोधों को सामान्य परिस्थितियों में पूरा
                    करने में देरी या असमर्थता का कारण बन सकती है
                  </li>
                  <br />
                  <li>
                    {" "}
                    HAFELE और इसके किसी भी निदेशक, अधिकारी, एजेंसियों और उनके
                    संबंधित कर्मचारियों के खिलाफ इस गतिविधि/कार्यक्रम के
                    परिणामस्वरूप कोई भी विवाद, दावा या कानूनी कार्रवाई विशेष रूप
                    से मुंबई न्याय क्षेत्र के अधीन होगी।
                  </li>
                  <br />
                  <li>
                    {" "}
                    इन शर्तों को समय-समय पर HAFELE INDIA PRIVATE LIMITED द्वारा
                    पूरी तरह या आंशिक रूप से संशोधित, परिवर्तित, हटाया या
                    प्रतिस्थापित किया जा सकता है, या कार्यक्रम को किसी भी समय
                    बिना किसी पूर्व सूचना के समाप्त किया जा सकता है।
                  </li>
                  <br />
                  <li>
                    {" "}
                    आप स्पष्ट रूप से सहमति और स्वीकृति देते हैं कि कार्यक्रम में
                    भागीदारी आपके एकमात्र जोखिम और परिणामों पर आधारित है।{" "}
                  </li>
                  <br />
                  <li>
                    {" "}
                    HAFELE किसी भी प्रकार की (i) कार्यक्रम या इस कार्यक्रम से
                    संबंधित जानकारी में त्रुटियाँ, चूक या अशुद्धियाँ, (ii)
                    कार्यक्रम में भागीदारी और/या इसे एक्सेस करने के परिणामस्वरूप
                    होने वाली व्यक्तिगत चोट या संपत्ति को होने वाले नुकसान,
                    (iii) HAFELE के सुरक्षित सर्वरों में अनधिकृत पहुंच या उपयोग
                    और/या उसमें समाहित किसी भी व्यक्तिगत जानकारी और/या वित्तीय
                    जानकारी, (iv) डेटा का कोई भी विलोपन या कार्यक्रम के माध्यम
                    से या उससे किसी भी प्रकार के ट्रांसमिशन में रुकावट या
                    अस्थायी रूप से निलंबन के लिए किसी भी प्रकार की जिम्मेदारी
                    स्वीकार नहीं करता है।
                  </li>
                  <br />
                  <li>
                    {" "}
                    HAFELE इस कार्यक्रम के माध्यम से कुछ तीसरे पक्ष के उत्पादों
                    और सेवाओं को उपलब्ध करा सकता है। HAFELE ऐसे उत्पादों और
                    सेवाओं के बारे में कोई प्रतिनिधित्व या गारंटी नहीं देता है
                    और आपको सलाह दी जाती है कि आप इन उत्पादों या सेवाओं पर भरोसा
                    करते समय उसी प्रकार सतर्कता बरतें जैसे कि आप इन्हें स्वतंत्र
                    रूप से प्राप्त करते। HAFELE किसी भी प्रकार से तीसरे पक्ष से
                    उपलब्ध कराए गए इन उत्पादों या सेवाओं के वारंटी, नुकसान या
                    हानि के लिए जिम्मेदार नहीं होगा।
                  </li>
                  <br />
                  <li>
                    {" "}
                    दायित्व की सीमा: किसी भी स्थिति में HAFELE आप या किसी तीसरे
                    पक्ष के लिए कोई भी प्रत्यक्ष, अप्रत्यक्ष, परिणामी,
                    उदाहरणात्मक, आकस्मिक, विशेष या दंडात्मक हानि के लिए
                    जिम्मेदार नहीं होगा, जिसमें कार्यक्रम में आपकी भागीदारी से
                    उत्पन्न होने वाली हानियाँ भी शामिल हैं। आप विशेष रूप से
                    सहमति देते हैं और समझते हैं कि HAFELE किसी भी तीसरे पक्ष की
                    सामग्री या अवैध आचरण के लिए आपके प्रति जिम्मेदार नहीं है और
                    आप अकेले उस जोखिम को स्वीकार करते हैं।
                  </li>
                  <br />
                  <li>
                    {" "}
                    सुरक्षा: आप सहमत हैं कि आप HAFELE को (i) कार्यक्रम में
                    भागीदारी का उपयोग करने के कारण उत्पन्न होने वाले किसी भी और
                    सभी दावों, हानियों, बाध्यताओं, लागतों या ऋणों और व्यय
                    (जिसमें वकील की फीस भी शामिल है) से रक्षा करेंगे, (ii) इन
                    शर्तों के किसी भी उल्लंघन से, (iii) आपकी लापरवाही, धोखाधड़ी
                    या जानबूझकर दुष्कर्म से। यह रक्षा और सुरक्षा दायित्व इन
                    शर्तों के बाद भी जारी रहेगा।
                  </li>
                  <br />
                  <li>
                    {" "}
                    इन शर्तों और नियमों का कोई भी प्रावधान, जिसे सक्षम न्यायालय
                    द्वारा अमान्य या लागू नहीं किया गया घोषित किया जाता है, तो
                    वह केवल उस अमान्य या लागू होने तक ही प्रभावी होगा, और शेष
                    प्रावधानों को अमान्य या लागू नहीं किया गया नहीं माना जाएगा।
                  </li>
                  <br />
                  <li>
                    {" "}
                    आप अपनी कमाई गए इनाम अंक का उपयोग किसी भी तरह से अपमानजनक,
                    आक्रामक या अवैध, अनैतिक या धोखाधड़ी के उद्देश्य के लिए नहीं
                    करेंगे, जो सार्वजनिक नीति के खिलाफ हो।
                  </li>
                  <br />
                  <li>
                    {" "}
                    यदि सदस्य इन शर्तों और नियमों का पालन करने में विफल होते हैं
                    या कार्यक्रम का कोई उल्लंघन, धोखाधड़ी या दुरुपयोग करते हैं,
                    तो इसके परिणामस्वरूप सभी HAFELE लॉयल्टी अंक जब्त किए जा सकते
                    हैं और बिना मुआवजे के सदस्य की सदस्यता समाप्त की जा सकती है।
                  </li>
                  <br />
                  <li>
                    {" "}
                    कैटलॉग में दिखाए गए सभी उत्पाद संकेतात्मक हैं और केवल
                    प्रतिनिधित्व के उद्देश्य से हैं।
                  </li>
                  <br />
                  <li>
                    {" "}
                    पुरस्कारों की पुनः मूल्यन प्रक्रिया केवल HAFELE द्वारा
                    निर्धारित प्रक्रिया के अनुसार होगी।
                  </li>
                  <br />
                  <li>
                    {" "}
                    पुरस्कारों और उत्पादों के खिलाफ उल्लिखित लॉयल्टी अंक कभी भी
                    बिना पूर्व सूचना के बदल सकते हैं। चयनित उत्पाद के लिए पुनः
                    मूल्यन उस उत्पाद की उपलब्धता पर निर्भर करेगा जब पुनः मूल्यन
                    अनुरोध उत्पन्न किया जाएगा।
                  </li>
                  <br />
                  <li>
                    {" "}
                    कैटलॉग में दिखाए गए सभी उत्पादों की उपलब्धता, माल और
                    आपूर्तिकर्ता की वारंटी/प्रतिबंधों के अधीन हैं, पुनः मूल्यन
                    के समय। HAFELE किसी भी प्रकार की वारंटी नहीं देता है जो
                    पुरस्कार कार्यक्रम में उपलब्ध उत्पादों/सेवाओं के संबंध में
                    हो।
                  </li>
                  <br />
                  <li>
                    {" "}
                    HAFELE को कोई भी पुरस्कार रद्द, बदलने या प्रतिस्थापित करने
                    का अधिकार है, बिना किसी पूर्व सूचना के। HAFELE या उसकी
                    एजेंसी किसी भी उत्पाद या वाउचर के चोरी, खो जाने या परिवहन
                    में क्षतिग्रस्त होने के लिए जिम्मेदार नहीं होंगे।
                  </li>
                  <br />
                  <li>
                    {" "}
                    यदि उत्पाद क्षतिग्रस्त अवस्था में प्राप्त होता है, तो उसे 48
                    घंटे के भीतर रिपोर्ट किया जाना चाहिए। HAFELE या एजेंसी इस
                    संबंध में 48 घंटे के बाद किसी भी तरह से जिम्मेदार नहीं होगी।
                  </li>
                  <br />
                  <li>
                    {" "}
                    HAFELE वास्तविक पुनः मूल्यन के समय उत्पादों के बदलाव या
                    उपलब्धता के लिए कोई जिम्मेदारी नहीं लेता है।
                  </li>
                  <br />
                  <li>
                    {" "}
                    कैटलॉग में उपलब्ध वस्त्रों पर उनकी सामान्य निर्माता वारंटी
                    होगी, जब तक कि इसके बारे में अन्यथा उल्लेख न किया गया हो।
                    सभी रख-रखाव/वारंटी/प्रतिस्थापन संबंधित मुद्दों के लिए,
                    इन्फ्लुएंसर को सीधे निर्माता/अधिकृत एजेंट/वितरक से संपर्क
                    करना होगा।
                  </li>
                  <br />
                  <li>
                    {" "}
                    इस कार्यक्रम के तहत सदस्य बनने पर, सदस्य/इन्फ्लुएंसर और
                    HAFELE के बीच कोई संयुक्त उद्यम, साझेदारी, रोजगार या एजेंसी
                    संबंध नहीं बनता है।
                  </li>
                  <br />
                  <li>
                    {" "}
                    इन्फ्लुएंसर/सदस्य/आप अपनी सहमति देते हैं कि इस कार्यक्रम के
                    तहत सफल सदस्य बनने पर, HAFELE कार्यक्रम से संबंधित जानकारी
                    केवल इलेक्ट्रॉनिक मोड जैसे SMS, ईमेल, व्हाट्सएप या किसी अन्य
                    उचित इलेक्ट्रॉनिक संचार मोड के माध्यम से संप्रेषित करेगा।
                  </li>
                  <br />
                  <li>
                    {" "}
                    इन्फ्लुएंसर/सदस्य/आप अपनी सहमति देते हैं कि आप नाबालिग नहीं
                    हैं और/या 18 वर्ष से कम उम्र के नहीं हैं, और इसके अलावा आपने
                    1875 के मेजोरिटी एक्ट के तहत इस कार्यक्रम के तहत पंजीकरण की
                    तिथि पर 18 वर्ष की आयु प्राप्त कर ली है।
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hindi;
